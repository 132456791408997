import { useEffect, useContext } from "react";
import authServices from "../../services/authServices";
import { useNavigate } from "react-router-dom";
import { appContext } from '../../App';


export const Logout = () => {

    const navigate = useNavigate();
    const userStatus = useContext(appContext);

    useEffect(() => {
        authServices.logout().then(() => {
            userStatus?.checkAuth()
            navigate("/")
            navigate(0)
        })
    },[])

  return (
    <div>Vous allez être deconnecté...</div>
  )
}
