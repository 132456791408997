
export const HomeCards = ({data}: any) => {

  const color = () => {
    switch(data.amount) {
      case "verified" :
        return " Verifié 🟢";
      case "unverified":
        return "Non Verifié 🔴";
      case "pending":
        return "En attente de verification 🟠";
      default:
        return "status en attente..."
    }
  }
  return (
    <div className="p-2 flex flex-col justify-center items-center text-center m-2 h-[150px] sm:w-[290px] w-[90vw] hover:bg-[#ffffff]/10 duration-200 bg-[#ffffff]/5 shadow-md rounded-md">
        <h3 className="text-[16px] text-[#FCE415] m-1">{data.name} :</h3>
        <div className="w-[80%] h-[1px] bg-[#ffffff]/5 m-1"></div>
        <h2 className="text-[20px] m-2">{data.unit != null ? data.amount : color()}<span className="text-[20px]"> {data.unit}</span></h2>
    </div>
  )
}
