import axios from "axios";

class promoCodeService {
    async get() {
        let response = await fetch(process.env.REACT_APP_API_URL+"/promo/get",{
            credentials: "include"
          });
        let data = response.json()
        return data;
    }

    async getSingle(code: string) {

        return axios({
          method: 'get',
          url: process.env.REACT_APP_API_URL + "/promo/getsingle/" + code,
          withCredentials:true
        })
    }
}

export default new promoCodeService()