import { useState } from 'react';
import userServices from '../../services/userServices';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';

export const ForgotPassword = () => {

  const [email, setEmail] = useState('');

  const onSubmit = (e : any) => {
    e.preventDefault()
    if(email) {
      userServices.resetPasswordRequest(email).then((res : any) => {
        if(res.data.error) {
          toast.error(res.data.error)
        } 

        if(res.data.message) {
          toast.success(res.data.message)
        }
      })
    }
  }

  return (
    <div className='flex flex-col justify-center items-center h-[100vh]'>
        <form onSubmit={(e) => onSubmit(e)} className="text-white flex flex-col w-full sm:w-[30rem] p-2 justify-center items-center text-left">
            <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            />
            <label className="mb-1 text-gray-500 w-full" htmlFor="password">Adresse Email du compte :</label>
            <input value={email} onChange={(e) => setEmail(e.target.value)} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="johndoe@gmail.com" />
            <Link className="hover:text-[#FCE415] duration-500" to="/auth/login">Connexion</Link>
            <input type="submit" className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold w-[70%] text-black cursor-pointer' value="Envoyer l'email"/>
        </form>
    </div>
  )
}
