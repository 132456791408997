import { useEffect, useState, useContext } from "react";
import { appContext } from "../../App";
import { HomeCards } from "../../components/dashboard/homeCards";
import userServices from "../../services/userServices";
import { ToastContainer, toast } from 'react-toastify';
import { BiSave } from 'react-icons/bi';
import { AiFillDelete } from 'react-icons/ai';
import voucherServices from "../../services/voucherServices";
import { TabView, TabPanel } from 'primereact/tabview';
import adminVoucherServices from "../../services/admin/adminVoucherServices";
import moment from "moment";
import paymentServices from "../../services/paymentServices";

export const DashboardHome = () => {

  const userStatus = useContext(appContext);
  const [voucher, setVoucher]: any = useState("");
  const [statToggler, setStatToggler] = useState(0);
  const[reloader, setReloader]: any = useState(0);

  const[verificationFiles, setVerificationFiles]: any = useState({});
  const requiredFiles: any = {
    idCard: {
      name: "Carte d'identité recto/verso",
    },
    driverLicense: {
      name: "Permis de conduire",
    },
    vehicleRegistration: {
      name: "Carte grise de la moto",
    },
    vehicleInsurance: {
      name: "Assurance de la moto",
    },
    // userInsurance: {
    //   name: "Assurance résponsabilité civile",
    // },
    // proofOfAddress: {
    //   name: "Justificatif de domicile",
    // },
  };


  useEffect(() => {
    userServices.getstats().then((res) => {
      setCardData([
        {
          name: "Membre depuis",
          amount: res.member_since,
          unit: ""
        },
        // {
        //   name: "Total des dépenses",
        //   amount: res.total_bill.length > 0 ? res.total_bill[0].total : 0,
        //   unit: "€"
        // },
        {
          name: "Status du compte",
          amount: res.user.status,
          unit: null
        },
        {
          name: "Solde du compte",
          amount: res.user.balance ? res.user.balance : 0,
          unit: "€"
        }

      ])
    })
  }, [statToggler,userStatus])

  const submitForm = (e: any) => {
    e.preventDefault();
    voucherServices.creditBalance(voucher)
    .then((res) => {
      setStatToggler(statToggler+1)
      userStatus?.checkAuth()
      toast.success(res.data.message)
    })
    .catch((error: any) => {
      toast.error(error.response.data.error)
    })
  }

  const verificationSubmitForm = (e: any) => {
    e.preventDefault();
    userServices.requestForVerification(verificationFiles).then((res) => {
      toast.success(res.data.message)
      userStatus?.checkAuth();
    }).catch((error: any) => {
      toast.error(error.response.data.error);
    })
  }

  const [cardData, setCardData]: any = useState([
    // {
    //   name: "Total des factures",
    //   amount: 0,
    //   unit: "€"
    // },
    {
      name: "Membre depuis",
      amount: 0,
      unit: "jours"
    },
    {
      name: "Statut du compte",
      amount: "",
      unit: null
    },
    {
      name: "Solde du compte",
      amount: "",
      unit: "€"
    }
  ])

  const [promos, setPromos] = useState([]);
  const[promoReloader, setPromoReloader]: any = useState(0);
  const [vouchers, setVouchers] = useState([]);
  const [payload, setPayload]: any = useState({
    value: 0,
    expire: moment().add(12, 'months')
  });
  
  useEffect(() => {
    adminVoucherServices.get().then((res) => {
      setVouchers(res.data)
    })
  }, [reloader])

  const postVoucher = (e: any) => {
    e.preventDefault();
    // adminVoucherServices.create(payload)
    // .then((res) => {
    //   setPayload({
    //     ...payload, value: 0
    //   })
    //   setReloader(reloader + 1)
    //   toast.success(res.data.message)
    // })
    // .catch((error) => toast.error(error.response.data.error))
    paymentServices.createCheckoutSession(undefined, null, null, false, null, null, payload.value)
    .then(
      (res) => {
        window.location = res.data.url;
      },
      (error) => {
        toast.error(error)
      }
    )
    .catch(
      (error) => {
        toast.error(error)
      }
    )
  }


  return (
    <div className="w-full min-h-[100vh] flex flex-col justify-start items-center pt-[120px] text-center">
        <div className="w-full h-auto flex justify-center items-center flex-wrap">
          {cardData.map((data: any, key: number) => (
            <HomeCards key={key} data={data}/>
          ))}
        </div>
        <form onSubmit={(e) => submitForm(e)} className="text-white flex flex-col sm:w-[400px] w-[90%] p-2 justify-center items-center text-left">
        <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
            <label className="mb-1 text-gray-500 w-full" htmlFor="password">Ajouter un code promo :</label>
            <input className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded sm:w-full w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text" placeholder="NWEU07-98GW93-VAXAU7-Z1UK88" value={voucher} onChange={(e) => setVoucher(e.target.value)}/>
            <input type="submit" className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold w-[50%] text-black cursor-pointer' value="Créditer"/>
        </form>
        <div className="flex flex-col justify-center items-center p-2">
            {userStatus?.status == "verified" || userStatus?.status == "pending" ? 
            <div className="flex flex-col justify-start items-center p-4">
            <TabView className="m-2">
              <TabPanel header="Bon d'achat">
                  <form onSubmit={(e: any) => postVoucher(e)} className="m-4 text-white flex flex-col sm:w-[400px] w-[90%] p-2 justify-center items-center text-left">
                    <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    />
                    <h3 className="text-[20px] mb-4">Acheter un bon d'achat :</h3>
                    <label className="mb-1 text-gray-500 w-full">Valeur :</label>
                    <input className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded sm:w-full w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text" placeholder="25" value={payload.value} onChange={(e) => setPayload({...payload, value: e.target.value})}/>
                    <input type="submit" className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold w-[50%] text-black cursor-pointer' value="Payer"/>
                  </form>
              </TabPanel>
            </TabView>
            <TabView className="m-2">
              <TabPanel header="Bon d'achat">
              <table className="text-sm text-left md:min-w-[60%] overflow-x-auto self-start">
                <thead className="text-xs text-white/90 uppercase">
                    <tr>
                        <th className="px-6 py-3">id</th>
                        <th className="px-6 py-3">Code</th>
                        <th className="px-6 py-3">Valeur</th>
                        <th className="px-6 py-3">Expire</th>
                    </tr>
                </thead>
                <tbody className='[&>*:nth-child(odd)]:bg-[#23272F] [&>*:nth-child(even)]:bg-transparent'>
                {vouchers.map((voucher: any, key: number) => (
                    <tr key={key} className="bg-white dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{voucher._id}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{voucher.code}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{voucher.value} €</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{moment(voucher.expire).format("DD/MM/YYYY")}</th>
                    </tr>
                ))}
                    
                </tbody>
              </table>
              </TabPanel>
            </TabView>
          </div> : 
              <>
                <h3 className='mt-2 m-2 text-[14px]'>Fournissez vos documents pour valider votre compte :</h3>
                <form onSubmit={(e) => verificationSubmitForm(e)}>
                  <div className="flex justify-center items-center flex-wrap">
                      {
                        Object.keys(requiredFiles).map(k => {
                          return <div className="flex flex-col justify-center items-center m-2">
                            <label className="mb-1 text-gray-500 w-full m-2" htmlFor="">{requiredFiles[k].name}</label>
                            <input onChange={(e: any) => setVerificationFiles({...verificationFiles, [k]: e.target.files[0]})} type="file" id={k} className="hidden"/>
                            <label htmlFor={k} className="bg-[#ffffff]/5 p-3 rounded-md hover:bg-[#ffffff]/10 duration-200 cursor-pointer m-2">Choisissez votre fichier</label>
                            {!!verificationFiles[k] && <label htmlFor={k} className="m-1">{verificationFiles[k]?.name}</label>}
                          </div>
                        })
                      }
                      <input type="submit" className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold w-[50%] text-black cursor-pointer' value="Envoyer"/>
                  </div>
                </form>
              </>
            }
        </div>
        
    </div>
  )
}
