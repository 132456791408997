import React, { useEffect, useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { TiDelete } from "react-icons/ti"
import offerServices from '../services/offerServices';
import { ToastContainer, toast } from 'react-toastify';
import moment, { duration } from "moment";
import garageServices from '../services/garageServices';
import { Calendar } from 'primereact/calendar'; 
import { Checkbox } from 'primereact/checkbox';
import reservationService from '../services/reservationServices';
import promoCodeServices from '../services/promoCodeServices';
import { appContext } from "../App";
import paymentServices from '../services/paymentServices';

export const OfferCheckout = () => {
    const navigate = useNavigate();  
    const { id } = useParams();
    const [offer, setOffer]: any = useState();
    const userStatus = useContext(appContext);
    const [promo_code, setPromoCode] = useState("");
    const [selectedGarage, setSelectedGarage]: any = useState();
    const [balanceCheckbox, setBalanceCheckbox] = useState(false);
    const [isElectric, setIsElectric] = useState(false);
    const [approved, setApproved] = useState(false);
    const [unavailableIntervals, setUnavailableIntervals] = useState({
      "maxPlaces": 0,
      "intervalsByPlace": [
        
      ]
    });
    const [viewDateStart, setViewDateStart] = useState(new Date());
    const [viewDateEnd, setViewDateEnd] = useState(new Date());
    const [disabledStartDates, setDisabledStartDates]: any[] = useState([]);
    const [disabledEndDates, setDisabledEndDates]: any[] = useState([]);

    const handleMonthChanged = (isStart: any, e: any) => {
      const currentMonth = e.month - 1; // JavaScript months are 0-indexed
      const currentYear = e.year;
      
      let daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
      let newDisabledDates = [];

      for (let i = 1; i <= daysInMonth; i++) {
          const currentDate = new Date(currentYear, currentMonth, i);
          let isDisabled = true;

          for (let intervals of unavailableIntervals.intervalsByPlace) {
              let intersects = false;
              for (let interval of intervals) {
                  const startDate = new Date(interval.start);
                  const endDate = interval.end ? new Date(interval.end) : null;

                  if (currentDate >= startDate && (!endDate || currentDate <= endDate)) {
                      intersects = true;
                      break;
                  }
              }
              if (!intersects) {
                  isDisabled = false;
                  break;
              }
          }

          if (isDisabled) {
              newDisabledDates.push(currentDate);
          }
      }

      if (isStart) {
          setDisabledStartDates(newDisabledDates);
      } else {
          setDisabledEndDates(newDisabledDates);
      }
    }

    const [payload, setPayload]: any = useState({
      promo: promo_code,
      starting_date: null,
      ending_date: null,
      total: 0,
      balance: false,
      subscription: false,
      balance_amount: 0,
      useBalance: 0,
      useElec: false,
    });

    let dateGap = isNaN(Math.ceil(moment.duration(moment(payload.ending_date).diff(moment(payload.starting_date))).asDays())) ? 0 : Math.ceil(moment.duration(moment(payload.ending_date).diff(moment(payload.starting_date))).asDays()) == 0 ? 1 : Math.ceil(moment.duration(moment(payload.ending_date).diff(moment(payload.starting_date))).asDays()) >= 1 ? Math.ceil(moment.duration(moment(payload.ending_date).diff(moment(payload.starting_date))).asDays())+1 : 0;

    useEffect(() => {
      
      if(balanceCheckbox) {
        if((payload.total - (userStatus?.balance ? userStatus?.balance : 0)) < 0) {
          setPayload({...payload, balance: balanceCheckbox, balance_amount: payload.total, total: 0});
        } else {
          setPayload({...payload, balance: balanceCheckbox, balance_amount: userStatus?.balance, total: payload.total - (userStatus?.balance ? userStatus?.balance : 0)});
        }
      } else {
        setPayload({...payload, balance: balanceCheckbox, balance_amount: 0, total: payload.total + payload.balance_amount});
      }

    },[balanceCheckbox])

    useEffect(() => {
      if(id) {
        offerServices.getSingle(id)
        .then((res) => {
          if(res) {
            setOffer(res)
            setSelectedGarage(res.garage_id)
            if(res.commitment) {
              dateGap = res.duration
              setPayload({...payload, subscription: res.subscribe, total: res.commitment ? res.duration * res.daily_cost : 0, starting_date: moment(Date.now()).format("MM-DD-YYYY"), ending_date: moment(Date.now()).add(res.duration, "days").format("MM-DD-YYYY")})
            } else {
              setPayload({...payload, subscription: res.subscribe})
            }
          } else {
            return navigate('/');
          }
        })
      }
    },[])

    useEffect(() => {
      const now = new Date();
      handleMonthChanged(true, {month: now.getMonth()+1, year: now.getFullYear()})
      handleMonthChanged(false, {month: now.getMonth()+1, year: now.getFullYear()})
    },[unavailableIntervals])

    useEffect(() => {
      if(selectedGarage) {
        reservationService.getunavailabledates(selectedGarage).then((res) => {
          setUnavailableIntervals(res.data);
        })
      }
    },[selectedGarage])

    useEffect(() => {
      setPayload({...payload, total: dateGap * offer?.daily_cost})
    },[payload.ending_date || payload.starting_date])

    const updateTotal = () => {

      promoCodeServices.getSingle(promo_code).then((res) => {
        toast.success("Promo code ajouté.");
        setPayload({...payload, total: offer?.commitment ? offer?.duration * offer?.daily_cost * (1 - res.data.data.discount / 100) : dateGap * offer?.daily_cost * (1 - res.data.data.discount / 100), promo: res.data.data.code})
      }).catch(error => {
        setPromoCode('');
        setPayload({...payload, promo: ""});
        toast.error(error.response.data.error);
      })
    }

    const handleSubmit = (e: any) => {
      e.preventDefault()

      if(payload.starting_date && payload.ending_date || offer?.subscribe) {
        paymentServices.createCheckoutSession(offer._id, payload.starting_date, payload.ending_date, isElectric).then((res) => {
          toast.success("Redirection vers le paiement...");
          // window.open(res.data.url, "_blank", "noreferer")
          window.location = res.data.url
        }).catch(error => {
          toast.error(error.response.data.error);
        })
      } else {
        toast.error("Veuillez remplir tout les champs : date de debut et de fin et selectionner un garage");
      }
    }
    
    
  return (
    <div className="w-full h-[100vh] flex flex-col justify-start items-center pt-[120px]">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {/* <img className="mb-4" src={process.env.PUBLIC_URL+ "/assets/images/black_garage.png"} alt="blackgarage logo" /> */}
      <p className='text-[5rem] font-black m-2'>{offer?.name}</p>
      {offer
        ? <>
      {!offer?.subscribe ? <h3 className='text-[14px]'>Choisissez les dates de votre choix.</h3> : null}
      <form onSubmit={(e) => handleSubmit(e)} className='flex flex-col justify-center items-center'>
        {!offer?.subscribe ?
        <div className='flex justify-center items-center'>
        <Calendar panelClassName='z-20' minDate={new Date()} dateFormat="dd-mm-yy" onMonthChange={(e)=>{handleMonthChanged(true, e) }} viewDate={viewDateStart} onViewDateChange={(e)=>{setViewDateStart(e.value); handleMonthChanged(true, { month: e.value.getMonth() + 1, year: e.value.getFullYear() })}} disabledDates={disabledStartDates} placeholder="DD-MM-AAAA" className="m-2" value={payload.starting_date} onChange={(e) => setPayload({...payload, starting_date: e.target.value})}></Calendar>
        <Calendar panelClassName='z-20' minDate={payload.starting_date || new Date()} dateFormat="dd-mm-yy" onMonthChange={(e)=>{handleMonthChanged(false, e) }} viewDate={viewDateEnd} onViewDateChange={(e)=>{setViewDateEnd(e.value); handleMonthChanged(false, { month: e.value.getMonth() + 1, year: e.value.getFullYear() })}} disabledDates={disabledEndDates} placeholder="DD-MM-AAAA" className="m-2" value={payload.ending_date} onChange={(e) => setPayload({...payload, ending_date: e.target.value})}></Calendar>
     </div> : null}
     <h3 className='text-[14px] underline m-2'>{offer?.commitment ? 'Offre avec engagement ' + offer?.duration + (offer?.subscribe ? ' mois' : ' jours') + '.' : 'Offre sans engagement.'}</h3>
      <div className='flex flex-col justify-center items-center w-full sm:w-[30rem]'>
        {/* <div className='flex justify-between items-center w-full border-b-[1px] uppercase'>
          <p className='text-[14px] font-semibold m-2'>Offre :</p>
          <p className='text-[14px] font-semibold m-2'>{offer?.name}</p>
        </div> */}
        {payload.starting_date && payload.ending_date && !offer?.subscribe
          ? <div className='flex justify-between items-center w-full border-b-[1px]'>
              <p className='text-[14px] font-semibold m-2'>Réservation</p>
              <p className='text-[14px] font-semibold m-2'>{dateGap} x {offer?.daily_cost} €</p>
            </div>
          : <div className='flex justify-between items-center w-full border-b-[1px]'>
              <p className='text-[14px] font-semibold m-2'>Réservation</p>
              <p className='text-[14px] font-semibold m-2'>{offer?.monthly_cost} € / mois</p>
            </div>}
        {isElectric && offer?.electricity_cost
          ? <div className='flex justify-between items-center w-full border-b-[1px] uppercase'>
              <p className='text-[14px] font-semibold m-2'>+ électricité</p>
              <p className='text-[14px] font-semibold m-2'>{offer.electricity_cost} € {offer.subscribe ? "/ mois" : "/ jour"}</p>
            </div>
          : null}
        {payload.promo && !offer?.subscribe ? 
        <div className='flex justify-between items-center w-full border-b-[1px]'>
          <p className='text-[14px] font-semibold m-2'>Code promotionnel :</p>
          <p className='text-[14px] font-semibold m-2 flex items-center'>{payload.promo}</p>
        </div>: null}
        <div className={'flex justify-between items-center w-full uppercase' + (offer?.subscribe ? ' border-t-[1px]' : '')}>
          <p className='text-[14px] font-semibold m-2'>TOTAL</p>
          <p className='text-[14px] font-semibold m-2'>{offer?.subscribe ? ((offer.monthly_cost + (isElectric ? offer.electricity_cost : 0)) + ' € / mois') : (isNaN(payload.total) ? "--" : ((payload.total + (isElectric ? offer.electricity_cost * dateGap : 0)) + ' €'))}</p>
        </div>
      </div>
        {!offer?.subscribe
          ? <div className='flex items-center justify-center m-1 w-full'>
              <input onChange={(e) => setPromoCode(e.target.value)} className="focus:ring-1 m-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 focus:outline-none focus:shadow-outline duration-100" type="text" placeholder="Code promotionnel" />
              <a onClick={() => {
                if(dateGap == 0) {
                  toast.error("Veuillez d'abbord selectionner une date.");
                  return;
                }
                if(balanceCheckbox) {
                  toast.error("Vous ne pouvez pas utiliser de code promo si vous utilisez déjà votre solde.");
                  return;
                }
                setPayload({...payload, promo: promo_code});
                updateTotal();
              }} className='p-3 bg-[#FCE415] m-1 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer'>Ajouter</a>
            </div>
          : null}
        {userStatus?.balance && !offer?.subscribe 
          ? <div className='flex'>
              <label htmlFor="cb1" className="p-checkbox-label m-2">Utiliser mon solde (<span className='text-green-400'>{userStatus?.balance}€</span>) : </label>
              <input disabled value={payload.useBalance} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="0" onChange={(e: any) => setPayload({...payload, useBalance: e.target.value})}/>
            </div>
          : null}
        {offer?.electricity_cost > 0 &&
          <div className='flex'>
            <Checkbox className="m-2" inputId="elec" checked={isElectric} onChange={(e) => setIsElectric(e.target.checked)}></Checkbox>
            <label htmlFor="elec" className="p-checkbox-label m-2">Je souhaite disposer d’une alimentation électrique afin de maintenir en charge la batterie de ma moto (consommation illimité).</label>          
          </div>
        }
        
        <div className='flex mt-5'>
          <Checkbox className="m-2" inputId="accept" checked={approved} onChange={(e) => setApproved(e.target.checked)}></Checkbox>
          <label className="p-checkbox-label m-2">Lu et approuvé le {(offer?.subscribe || (payload.starting_date && payload.ending_date)) ? <a href={process.env.REACT_APP_API_URL + "/reservation/getbail/" + offer?._id + (offer.subscribe ? "" : ("?startDate=" + (new Date(payload.starting_date.getTime() - (new Date()).getTimezoneOffset() * 60000)).toISOString() + "&endDate=" + (new Date(payload.ending_date.getTime() - (new Date()).getTimezoneOffset() * 60000)).toISOString()))} target="_blank" rel="noopener noreferrer" className="underline text-[#FCE415] hover:text-[#ffffff] cursor-pointer">bail</a> : "bail"} de location.</label>          
        </div>
        <input disabled={!(approved && (offer?.subscribe || (payload.starting_date && payload.ending_date)))} type="submit" className='p-3 m-1 rounded-xl bg-[#FCE415] hover:bg-[#FCE415]/80 disabled:bg-[#FCE415]/10 disabled:cursor-default transition duration-300 font-semibold text-black cursor-pointer' value="Payer"/>
      </form>
          </>
        : <></>}

    </div>
  )
}
