import axios from "axios";

class adminGarageServices {

    async get() {
        let response = await fetch(process.env.REACT_APP_API_URL+"/garage/get",{
            credentials: "include"
          });
        let data = response.json()
        return data;
    }

    async getSingle(id: any) {
        let response = await fetch(process.env.REACT_APP_API_URL+"/garage/get/"+ id,{
            credentials: "include"
          });
        let data = response.json()
        return data;
    }

    async create(payload: any) {
      return axios({
        method: 'post',
        url: process.env.REACT_APP_API_URL + "/garage/create/",
        withCredentials:true,
        data: {...payload}
      })
    }

    async removeImages(id: any) {
      return axios({
        method: 'post',
        url: process.env.REACT_APP_API_URL + "/garage/removeimages",
        withCredentials:true,
        data: id
      })
    }

    async update(payload: any) 
    {
      const formData = new FormData();
      formData.append('place', payload.place);
      formData.append('max_places', payload.max_places);
      formData.append('garage_id', payload.garage_id);
      Array.from(payload.images).forEach((file: any) => formData.append('images', file));
      return axios({
        method: 'patch',
        url: process.env.REACT_APP_API_URL + "/garage/update/",
        headers: {
          "Content-Type": "multipart/form-data",
          'Access-Control-Allow-Origin': '*',
        },
        withCredentials:true,
        data: formData
      })
    }
    
    async delete(id: string) {
      return axios({
        method: 'delete',
        url: process.env.REACT_APP_API_URL + "/garage/" + id,
        withCredentials:true,
      })
    }

}

export default new adminGarageServices();