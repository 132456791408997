
class offerService {
    async get(id: string) {
        let response = await fetch(process.env.REACT_APP_API_URL+"/offer/getall/" + id,{
            credentials: "include"
          });
        let data = response.json()
        return data;
    }

    async getSingle(id: string) {
        let response = await fetch(process.env.REACT_APP_API_URL+"/offer/get/" + id,{
            credentials: "include"
        });
        let data = response.json()
        return data;
    }
}

export default new offerService()