import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { Tag } from 'primereact/tag';

export default function PhoneCarousel({ products, statSinceJanuary, statSinceConnected }: any) {

    const slides = [
        {
            key: 1,
            title: "LE SAVIEZ VOUS ?",
            maintext: "93% des motos volées seraient directement hissées et embarquées dans un véhicule par les voleurs.",
            moretext: <p className="mt-5">81% des motos et scooters volés étaient munis d’un dispositif antivol électronique et ou mécanique</p>,
            source: <p className="mt-5 text-[10px] text-right">Source : FFMC / Rapports de la police et de la gendarmerie nationale</p>
        },
        {
            key: 2,
            title: "LE SAVIEZ VOUS ?",
            maintext: "En France, une moto ou un scooter est volé toutes les 10 minutes. 86 % de ces vols se déroulent en milieu urbain.",
            moretext: <p className="mt-5">Seul <span className="font-extrabold">BLACK GARAGE</span> apporte une réel sécurité.</p>,
            source: <p className="mt-5 text-[10px] text-right">Source : FFMC / Rapports de la police et de la gendarmerie nationale</p>
        },
        {
            key: 3,
            maintext: "Afin que BLACK GARAGE soit présent dans tous vos déplacements, faites nous part des lieux dans lequel vous souhaitez que votre moto soit réellement sécurisée.",
            moretext: <><p className="mt-5">Seul <span className="font-extrabold">BLACK GARAGE</span> apporte une réel sécurité.</p></>,
            source: <a href="mailto:contact@blackgarage.fr" className="mt-5 p-3 flex items-center justify-center rounded-md bg-[#FCE415] text-[14px] text-black hover:bg-[#ccb910] duration-300">Envoyer un message.</a>
        },
        {
            key: 4,
            title: "UNE QUESTION ?",
            maintext: "Faites nous en part ici !",
            moretext: <p>Nous répondrons volontiers à vos requêtes.</p>,
            source: <a href="mailto:contact@blackgarage.fr" className="mt-5 p-3 flex items-center justify-center rounded-md bg-[#FCE415] text-[14px] text-black hover:bg-[#ccb910] duration-300">Envoyer un message.</a>
        },
        {
            key: 5,
            title: "Statistiques :",
            maintext: "",
            moretext: <><p>Les vols de motos et de scooters en France ;</p><p>depuis le 1er janvier : <span className="font-extrabold">{statSinceJanuary}</span></p><p>depuis que vous êtes connecté : <span className="font-extrabold">{parseInt(statSinceConnected) + 1}</span></p></>
        },
    ]


    const productTemplate = (product: any) => {
        return (
            <div className="duration-300 brightness-[1] h-full w-full text-left items-center flex justify-center " style={{ objectFit: "cover", backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(/assets/images/carousel/m${product.key}.jpg)`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                <div className='w-full p-4 text-white text-[18px]'>
                    <h2 className='text-[24px]'>{product.title ?? product.title}</h2>
                    <p>{product.maintext ?? product.maintext}</p>
                    {product.moretext ?? product.moretext}
                    <p>{product.source ?? product.source}</p>
                </div>
            </div>
        );
    };

    return (
        <div className="card flex justify-content-center w-full">
            <Carousel className='w-full' value={slides} numVisible={1} numScroll={1} verticalViewPortHeight="460px"
                itemTemplate={productTemplate} />
        </div>
    )
}