import axios from "axios";

class adminUserServices {

    async get() {
        let response = await fetch(process.env.REACT_APP_API_URL+"/user/",{
            credentials: "include"
          });
        let data = response.json()
        return data;
    }

    async getSingle(id: any) {
        let response = await fetch(process.env.REACT_APP_API_URL+"/user/getsingle/"+ id,{
            credentials: "include"
          });
        let data = response.json()
        return data;
    }

    async updateAdmin(payload: any) {
      return axios({
        method: 'put',
        url: process.env.REACT_APP_API_URL + "/user/adminedit",
        withCredentials:true,
        data: {...payload}
      })
    }
}

export default new adminUserServices()