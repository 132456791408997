const LogoWithText = () => {
    return (
        <svg className="xl:w-[30%] w-[50%]" viewBox="348 440 170 75" style={{ transform: "rotate3d(1, 0, 0, 180deg)" }}>
          <path
            d="M0 0c.254-.039.561-.039 1.008-.039 1.163 0 2.147.466 2.147 1.626 0 1.106-1.008 1.531-2.321 1.531H0Zm0 5.596h.697c1.491 0 2.209.525 2.209 1.356 0 .755-.583 1.24-1.762 1.24-.583 0-.912-.038-1.144-.077Zm-3.468 4.823c.795.155 2.519.291 4.127.291 1.859 0 2.984-.136 3.991-.64 1.026-.482 1.759-1.413 1.759-2.69 0-1.086-.6-2.151-2.129-2.731V4.61c1.589-.445 2.556-1.627 2.556-3.177 0-1.24-.6-2.209-1.473-2.868-.967-.737-2.595-1.162-5.383-1.162-1.588 0-2.748.1-3.448.234z"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(426.623 479.316)"
          />
          <path
            d="M0 0h3.505v-10.13h5.016v-2.925H0Z"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(435.38 489.93)"
          />
          <path
            d="m0 0-.603 2.344c-.193.735-.444 1.801-.637 2.573h-.038c-.195-.772-.388-1.838-.582-2.573L-2.421 0Zm-2.885-2.576-.736-3.021h-3.623l3.797 13.055h4.646L5.093-5.597H1.317L.481-2.576z"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(452.06 482.473)"
          />
          <path
            d="M0 0c-.481-.25-1.722-.54-3.271-.54-4.88 0-7.031 3.039-7.031 6.507 0 4.591 3.37 6.931 7.265 6.931 1.491 0 2.711-.288 3.254-.558l-.681-2.77a6.31 6.31 0 0 1-2.418.465c-1.995 0-3.739-1.182-3.739-3.835 0-2.362 1.411-3.836 3.816-3.836.831 0 1.78.157 2.324.368z"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(468.02 477.223)"
          />
          <path
            d="M0 0h3.467v-5.541h.04c.271.486.559.971.83 1.436L6.855 0h4.301L7.012-5.559l4.298-7.496H7.261L4.512-7.768 3.467-9.104v-3.951H0Z"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(469.922 489.93)"
          />
          <path
            d="M0 0c-.987-.328-2.809-.734-4.514-.734-2.557 0-4.297.639-5.48 1.763-1.219 1.162-1.86 2.845-1.86 4.705.019 4.552 3.411 6.856 7.633 6.856 1.762 0 3.099-.31 3.777-.638l-.698-2.809c-.736.331-1.628.562-3.098.562-2.15 0-3.935-1.122-3.935-3.778 0-2.44 1.571-3.854 3.703-3.854.48 0 .929.04 1.104.118v2.245h-1.743v2.711H0Z"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(434.467 454.228)"
          />
          <path
            d="m0 0-.598 2.345c-.197.733-.449 1.799-.642 2.572h-.039c-.192-.773-.385-1.839-.578-2.572L-2.419 0Zm-2.885-2.578-.738-3.019h-3.618l3.795 13.055h4.648L5.095-5.597h-3.78L.484-2.578z"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(442.973 459.227)"
          />
          <path
            d="M0 0h.908c1.318 0 2.073.642 2.073 1.608 0 .969-.64 1.531-1.8 1.531-.66 0-1.008-.04-1.181-.077Zm-3.469 5.444c.988.155 2.477.291 4.222.291 1.997 0 3.43-.272 4.417-1.027.831-.642 1.278-1.569 1.278-2.829 0-1.625-1.221-2.77-2.227-3.156v-.057c.852-.389 1.334-1.222 1.666-2.344.384-1.337.813-3.234 1.063-3.737H3.385c-.192.386-.541 1.393-.908 3.019-.347 1.549-.834 1.88-1.918 1.88H0v-4.899h-3.469z"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(453.204 461.045)"
          />
          <path
            d="m0 0-.6 2.345c-.197.733-.447 1.799-.638 2.572h-.043c-.194-.773-.383-1.839-.579-2.572L-2.421 0Zm-2.885-2.578-.735-3.019h-3.623l3.796 13.055h4.645L5.093-5.597H1.319L.482-2.578z"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(468.196 459.227)"
          />
          <path
            d="M0 0c-.99-.328-2.811-.734-4.515-.734-2.557 0-4.297.639-5.48 1.763-1.221 1.162-1.857 2.845-1.857 4.705.017 4.552 3.407 6.856 7.628 6.856 1.762 0 3.099-.31 3.775-.638l-.697-2.809c-.734.331-1.624.562-3.098.562-2.149 0-3.931-1.122-3.931-3.778 0-2.44 1.571-3.854 3.696-3.854.488 0 .933.04 1.105.118v2.245h-1.74v2.711H0Z"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(485.71 454.228)"
          />
          <path
            d="M0 0h-4.685v-2.403H.564v-2.865h-8.756V7.787H.294V4.918h-4.979V2.829H0z"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(496.09 458.897)"
          />
          <path
            d="M0 0c1.971 0 3.572-1.6 3.572-3.572a3.567 3.567 0 0 0-2.339-3.342v-7.895h-2.467v7.895A3.565 3.565 0 0 0 0 0m-10.652 13.446c0 5.875 4.778 10.652 10.652 10.652 5.143 0 9.443-3.661 10.437-8.517h4.737C14.132 23.025 7.725 28.771 0 28.771c-8.453 0-15.329-6.874-15.329-15.325V5.391h-3.769V-20.59H2.226L8.793 5.391h-19.445z"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(380.935 474.188)"
          />
          <path
            d="M0 0v.002L-.002 0Z"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(399.777 458.389)"
          />
          <path
            d="M0 0c.003-.001.008-.001.01-.004L.694-.4Z"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(393.202 479.532)"
          />
          <path
            d="m0 0-6.651 3.881L-.012.002 0 0"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(402.974 473.833)"
          />
          <path
            d="M0 0c-1.566.432-4.922 1.379-4.922 1.379l.491 1.466c.18.537.338.875.595 1.044.263.173.631.173 1.239.025.26-.063 6.471-1.601 6.712-1.657.176-.038.314-.047.428-.002.141.047.273.331.519.822.173.353.216.593.173.778-.042.191-.213.338-.522.531L1.986 5.978c-3.11 1.805-5.237 3.045-6.689 3.891a.365.365 0 0 1 .05-.013l-2.426 1.418.712-.416.04-.039c-.975.575-1.318.778-1.437.852l-.063.037s-2.664-9.922-3.161-11.745c-.21-.771.024-1.333.357-1.644.534-.488 1.86-.541 1.86-.541s5.259-.413 6.6-.498c.502-.031.609-.196.444-.67C-3.25-7.778-3.7-9.082-3.836-9.469H-1.2l.002.002 2.349 6.732c.04.136.082.286.125.443C1.615-1.157 1.147-.322 0 0"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(400.976 467.857)"
          />
          <path
            d="m0 0-.712.416-.684.396C-1.277.738-.935.535.04-.039L0 0"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(394.608 478.716)"
          />
          <path
            d="m0 0-6.64 3.879a.35.35 0 0 0-.049.013C-5.237 3.046-3.11 1.806 0 0"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(402.962 473.835)"
          />
          <path
            d="M0 0c-.492 0-.969-.059-1.432-.165l1.323-2.664c.037 0 .072.003.109.003a3.64 3.64 0 0 0 3.635-3.636A3.64 3.64 0 0 0 0-10.102a3.643 3.643 0 0 0-3.64 3.64c0 .766.238 1.477.648 2.063l-1.347 2.715a6.433 6.433 0 0 1-2.125-4.778A6.471 6.471 0 0 1 0-12.928c3.563 0 6.461 2.9 6.461 6.466A6.467 6.467 0 0 1 0 0"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(411.075 466.526)"
          />
          <path
            d="M0 0c0-.628.137-1.221.378-1.756l5.531-3.227.561 1.054-.196.182-2.263 2.081s.494.818.827 1.391c.099.171.356.501.742.282.295-.168 2.373-1.289 2.675-1.452.164.453.257.936.257 1.445A4.256 4.256 0 1 1 0 0"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(393.291 481.791)"
          />
          <path
            d="M0 0c.008-.019.014-.042.025-.062l3.943-7.992c.02-.037.046-.066.067-.097a.997.997 0 0 1 .832-.462.998.998 0 0 1 .98 1.183.846.846 0 0 1-.083.263L1.819.825a1 1 0 0 1-1.34.452A.995.995 0 0 1 0 0"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(406.169 467.498)"
          />
          <path
            d="M0 0a2.279 2.279 0 0 1-2.277-2.275A2.282 2.282 0 0 1 0-4.555a2.282 2.282 0 0 1 2.277 2.28A2.279 2.279 0 0 1 0 0m0-5.136a2.863 2.863 0 0 0-2.861 2.861A2.863 2.863 0 0 0 0 .584a2.863 2.863 0 0 0 2.859-2.859A2.863 2.863 0 0 0 0-5.136"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(503.068 458.653)"
          />
          <path
            d="M0 0h.329c.373 0 .608.197.608.498 0 .334-.235.488-.594.488C.166.986.054.972 0 .96Zm-.549 1.343c.207.036.518.061.837.061.438 0 .734-.072.937-.242a.776.776 0 0 0 .259-.609.806.806 0 0 0-.547-.76v-.014c.216-.08.345-.294.421-.585.096-.379.176-.728.24-.847h-.572c-.045.091-.118.339-.202.716-.086.397-.229.523-.545.531H0v-1.247h-.549z"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(502.665 456.562)"
          />
        </svg>
    )
}

export default LogoWithText;
