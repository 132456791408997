import axios from "axios";
import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { appContext } from '../../App';
import { Rings } from "react-loading-icons";

export const Register = () => {

    const userStatus = useContext(appContext);

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [payload, setPayload]: any = useState({
        name: "",
        last_name: "",
        email: "",
        phone_number: "",
        password: "" 
    });
    const [repeatPassword, setRepeatPassword] = useState("");

    const [ successfullyRegistered, setSuccessfullyRegistered ] = useState(false);

    const submitForm = (e: any) => {
        e.preventDefault();
        if(payload.password != repeatPassword) {
            return toast.error('Les champs mot de passe ne correspondent pas.')
        }

        setIsLoading(true);

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + "/auth/register",
            withCredentials:true,
            data: payload
        }).then((response: any) => {
            if(response.status == 200) {
                userStatus?.checkAuth();
                toast.success("Inscription réussie, redirection...")
                setSuccessfullyRegistered(true);
                setIsLoading(false);
            }
        }).catch((error) => {
            setIsLoading(false);
            toast.error(error.response.data.error);
        });
    }

    return <>
        <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
        
        {isLoading
            ? <div className='flex flex-col justify-center items-center h-[100vh]'>
                <Rings />
            </div>
            : (successfullyRegistered
                ? <div className='flex flex-col justify-center items-center h-[100vh]'>
                    <p className="text-[2rem] text-center">Merci pour votre demande d’inscription.</p>
                    <p className="text-[1rem] text-center">Afin de valider votre compte et ainsi profiter de nos services et connaître l’endroit exact de nos différents points, <a className="text-[#FCE415]">connectez-vous et finalisez votre inscription</a> sur votre espace en nous transmettant les documents nécessaires.</p>
                    <Link to="/auth/login">
                        <button className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer'>Connexion</button>
                    </Link>
                </div>
                : <div className='flex flex-col justify-center items-center'>
                    <Link to="/">
                        <div className="mb-10 flex justify-items-center flex-col">
                            <svg className="w-[15rem]" viewBox="348 440 80 70" style={{transform: "rotate3d(1, 0, 0, 180deg)"}}>
                                <path
                                    d="M0 0c1.971 0 3.572-1.6 3.572-3.572a3.567 3.567 0 0 0-2.339-3.342v-7.895h-2.467v7.895A3.565 3.565 0 0 0 0 0m-10.652 13.446c0 5.875 4.778 10.652 10.652 10.652 5.143 0 9.443-3.661 10.437-8.517h4.737C14.132 23.025 7.725 28.771 0 28.771c-8.453 0-15.329-6.874-15.329-15.325V5.391h-3.769V-20.59H2.226L8.793 5.391h-19.445z"
                                    style={{
                                    fill: "#ffffff",
                                    fillOpacity: 1,
                                    fillRule: "nonzero",
                                    stroke: "none",
                                    }}
                                    transform="translate(380.935 474.188)"
                                />
                                <path
                                    d="M0 0v.002L-.002 0Z"
                                    style={{
                                    fill: "#ffffff",
                                    fillOpacity: 1,
                                    fillRule: "nonzero",
                                    stroke: "none",
                                    }}
                                    transform="translate(399.777 458.389)"
                                />
                                <path
                                    d="M0 0c.003-.001.008-.001.01-.004L.694-.4Z"
                                    style={{
                                    fill: "#ffffff",
                                    fillOpacity: 1,
                                    fillRule: "nonzero",
                                    stroke: "none",
                                    }}
                                    transform="translate(393.202 479.532)"
                                />
                                <path
                                    d="m0 0-6.651 3.881L-.012.002 0 0"
                                    style={{
                                    fill: "#ffffff",
                                    fillOpacity: 1,
                                    fillRule: "nonzero",
                                    stroke: "none",
                                    }}
                                    transform="translate(402.974 473.833)"
                                />
                                <path
                                    d="M0 0c-1.566.432-4.922 1.379-4.922 1.379l.491 1.466c.18.537.338.875.595 1.044.263.173.631.173 1.239.025.26-.063 6.471-1.601 6.712-1.657.176-.038.314-.047.428-.002.141.047.273.331.519.822.173.353.216.593.173.778-.042.191-.213.338-.522.531L1.986 5.978c-3.11 1.805-5.237 3.045-6.689 3.891a.365.365 0 0 1 .05-.013l-2.426 1.418.712-.416.04-.039c-.975.575-1.318.778-1.437.852l-.063.037s-2.664-9.922-3.161-11.745c-.21-.771.024-1.333.357-1.644.534-.488 1.86-.541 1.86-.541s5.259-.413 6.6-.498c.502-.031.609-.196.444-.67C-3.25-7.778-3.7-9.082-3.836-9.469H-1.2l.002.002 2.349 6.732c.04.136.082.286.125.443C1.615-1.157 1.147-.322 0 0"
                                    style={{
                                    fill: "#ffffff",
                                    fillOpacity: 1,
                                    fillRule: "nonzero",
                                    stroke: "none",
                                    }}
                                    transform="translate(400.976 467.857)"
                                />
                                <path
                                    d="m0 0-.712.416-.684.396C-1.277.738-.935.535.04-.039L0 0"
                                    style={{
                                    fill: "#ffffff",
                                    fillOpacity: 1,
                                    fillRule: "nonzero",
                                    stroke: "none",
                                    }}
                                    transform="translate(394.608 478.716)"
                                />
                                <path
                                    d="m0 0-6.64 3.879a.35.35 0 0 0-.049.013C-5.237 3.046-3.11 1.806 0 0"
                                    style={{
                                    fill: "#ffffff",
                                    fillOpacity: 1,
                                    fillRule: "nonzero",
                                    stroke: "none",
                                    }}
                                    transform="translate(402.962 473.835)"
                                />
                                <path
                                    d="M0 0c-.492 0-.969-.059-1.432-.165l1.323-2.664c.037 0 .072.003.109.003a3.64 3.64 0 0 0 3.635-3.636A3.64 3.64 0 0 0 0-10.102a3.643 3.643 0 0 0-3.64 3.64c0 .766.238 1.477.648 2.063l-1.347 2.715a6.433 6.433 0 0 1-2.125-4.778A6.471 6.471 0 0 1 0-12.928c3.563 0 6.461 2.9 6.461 6.466A6.467 6.467 0 0 1 0 0"
                                    style={{
                                    fill: "#ffffff",
                                    fillOpacity: 1,
                                    fillRule: "nonzero",
                                    stroke: "none",
                                    }}
                                    transform="translate(411.075 466.526)"
                                />
                                <path
                                    d="M0 0c0-.628.137-1.221.378-1.756l5.531-3.227.561 1.054-.196.182-2.263 2.081s.494.818.827 1.391c.099.171.356.501.742.282.295-.168 2.373-1.289 2.675-1.452.164.453.257.936.257 1.445A4.256 4.256 0 1 1 0 0"
                                    style={{
                                    fill: "#ffffff",
                                    fillOpacity: 1,
                                    fillRule: "nonzero",
                                    stroke: "none",
                                    }}
                                    transform="translate(393.291 481.791)"
                                />
                                <path
                                    d="M0 0c.008-.019.014-.042.025-.062l3.943-7.992c.02-.037.046-.066.067-.097a.997.997 0 0 1 .832-.462.998.998 0 0 1 .98 1.183.846.846 0 0 1-.083.263L1.819.825a1 1 0 0 1-1.34.452A.995.995 0 0 1 0 0"
                                    style={{
                                    fill: "#ffffff",
                                    fillOpacity: 1,
                                    fillRule: "nonzero",
                                    stroke: "none",
                                    }}
                                    transform="translate(406.169 467.498)"
                                />
                            </svg>
                            <p className="text-[2rem] text-center">Inscription</p>
                        </div>
                    </Link>
                    <form onSubmit={(e) => submitForm(e)} className="text-white flex flex-col w-full sm:w-[30rem] p-2 justify-center items-center text-left">
                        <label className="mb-1 text-gray-500 w-full" htmlFor="name">Nom :</label>
                        <input id="name" className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text" placeholder="John" onChange={(e) => setPayload({...payload, name: e.target.value})}/>

                        <label className="mb-1 text-gray-500 w-full" htmlFor="last_name">Prenom :</label>
                        <input id="last_name" className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text" placeholder="Doe" onChange={(e) => setPayload({...payload, last_name: e.target.value})}/>
                        
                        <label className="mb-1 text-gray-500 w-full" htmlFor="email">Email :</label>
                        <input id="email" className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text" placeholder="johndoe@mail.com" onChange={(e) => setPayload({...payload, email: e.target.value})}/>
                        
                        <label className="mb-1 text-gray-500 w-full" htmlFor="phone_number">Numéro de téléphone :</label>
                        <input id="phone_number" className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text" placeholder="0612345678" onChange={(e) => setPayload({...payload, phone_number: e.target.value})}/>

                        <label className="mb-1 text-gray-500 w-full" htmlFor="password">Mot de passe :</label>
                        <input id="password" className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="password" placeholder="*********" onChange={(e) => setPayload({...payload, password: e.target.value})}/>
                        
                        <label className="mb-1 text-gray-500 w-full" htmlFor="repeatPassword">Repeter le mot de passe :</label>
                        <input id="repeatPassword" className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="password" placeholder="*********" value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)}/>
                        
                        <Link className="hover:text-[#FCE415] duration-500" to="/auth/forgotpassword">Mot de passe oublié ?</Link>
                        <Link className="hover:text-[#FCE415] duration-500" to="/auth/login">Vous avez déjà un compte ? Cliquez ici.</Link>
                        <input type="submit" className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold w-[70%] text-black cursor-pointer' value="Inscription"/>
                    </form>
                </div>
            )
        }
    </>
}
