import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { appContext } from "../../../App";
import { ToastContainer, toast } from 'react-toastify'
import adminGarageServices from "../../../services/admin/adminGarageServices";


export const AdminCreateGarages = () => {
    const navigate = useNavigate();
    const [payload, setPayload]: any = useState({
        place: "",
        max_places: 0,
        adress: {
            street_number: "",
            street: "",
            city: "",
            postal_code: 0
        }
    });

    const submitForm = (e: any) => {
      e.preventDefault();
      adminGarageServices.create(payload)
      .then((res) => {
        toast.success(res.data.message)
      })
      .catch((error) => toast.error(error.response.data.error))
      
    }

  return (
    <div className="w-full min-h-[100vh] flex flex-col justify-start items-center pt-[120px] text-center p-2">
        <button onClick={() => navigate(-1)} type="submit" className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold min-w-[150px] text-black cursor-pointer' value="Connexion">Retour</button>
        <h1 className='text-[18px]'>Détails du garage</h1>
        <form onSubmit={(e) => submitForm(e)} className="text-white flex flex-col w-auto max-w-[800px] p-2 justify-center items-center text-left">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <div className='flex md:flex-row flex-col justify-center items-start'>
            <div className='m-4'>
              <label className="mb-1 text-gray-500 w-full" htmlFor="name">Emplacement :</label>
              <input value={payload.place} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="Saint-Etienne" onChange={(e) => setPayload({...payload, place: e.target.value})}/>
              
              <label className="mb-1 text-gray-500 w-full" htmlFor="last_name">Places maximales:</label>
              <input value={payload.max_places} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="4.25" onChange={(e: any) => setPayload({...payload, max_places: e.target.value})}/>
            </div>
            <div className='m-4'>
                <label className="mb-1 text-gray-500 w-full" htmlFor="name">Numero de la voie :</label>
                <input value={payload.adress.street_number} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="143" onChange={(e) => setPayload({...payload, adress: {...payload.adress, street_number: e.target.value}})}/>
                
                <label className="mb-1 text-gray-500 w-full" htmlFor="last_name">Nom de la rue / voie :</label>
                <input value={payload.adress.street} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="Rue de france" onChange={(e) => setPayload({...payload, adress: {...payload.adress, street: e.target.value}})}/>
                
                <label className="mb-1 text-gray-500 w-full" htmlFor="password">Code postal :</label>
                <input value={payload.adress.postal_code} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="75006" onChange={(e) => setPayload({...payload, adress: {...payload.adress, postal_code: e.target.value}})}/>
            </div>
          </div>
            <input type="submit" className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold w-[70%] text-black cursor-pointer' value="Sauvegarder"/>
        </form>
    </div>
  )
}