import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContainer, toast } from 'react-toastify';
import { AiOutlineDownload, AiOutlineClose } from "react-icons/ai";
import { useState, useEffect, useContext } from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import reservationServices from '../../services/reservationServices';
import moment from 'moment';

export const DashboardReservations = () => {

  const [reservations, setReservations] = useState([]);
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState(0);

  const fetchReservation = () => {
    reservationServices.get().then((res) => {
      setReservations(res)
    })
  }

  useEffect(() => {
    fetchReservation()
  }, [])

  const stopSubscription = (id: number) => {
    setVisible(false)
    reservationServices.stopSubscription(id).then((res : any) => {
      if(res.status == 200) {
        fetchReservation();
        toast.success("Abonnement résilié");
      }
    });
  }

  return (
    <div className="w-full min-h-[100vh] flex flex-col justify-start items-center pt-[120px] text-center p-2">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Dialog header="Header" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
          <p className="m-0">
            Voulez vous vraiment résilier votre abonnement ? (action irréversible)
            <div>
              <button onClick={() => setVisible(false)} className='p-3 bg-[#fc1515] rounded-xl hover:bg-[#fc1515]/80 transition duration-300 font-semibold text-black cursor-pointer m-1'>Annuler</button>
              <button onClick={() => stopSubscription(id)} className='p-3 bg-[#FCE415] rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer m-1'>Confirmer</button>
            </div>
          </p>
      </Dialog>
      <h1 className='text-[18px]'>Mes factures</h1>
        {reservations.length == 0 ? 
        <h3 className='mt-2 m-2 text-[14px]'>Aucune facture pour le moment.</h3>
        :
        <table className="text-sm text-left md:min-w-[60%] overflow-x-auto self-start">
            <thead className="text-xs text-white/90 uppercase">
                <tr>
                    <th className="px-6 py-3">N° de facture</th>
                    <th className="px-6 py-3">Date</th>
                    <th className="px-6 py-3">Debut - Fin</th>
                    <th className="px-6 py-3">Garage</th>
                    <th className="px-6 py-3">Code d'acces</th>
                    <th className="px-6 py-3">Total</th>
                    <th className="px-3 py-3">Reçu</th>
                    <th className="px-3 py-3">Bail</th>
                    <th className="px-3 py-3">Action</th>
                </tr>
            </thead>
            <tbody className='[&>*:nth-child(odd)]:bg-[#23272F] [&>*:nth-child(even)]:bg-transparent'>
                {reservations.map((reservation: any, key: number) => (
                    <tr key={key} className="bg-white dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{reservation._id}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{moment(reservation.createdAt).format("DD/MM/YYYY")}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{moment(reservation.startingDate).format("DD/MM/YYYY")} - <span className={`${moment(reservation.endingDate).add(1, 'days').isBefore(moment(Date.now())) ? "text-red-500" : "text-green-500"}`}>{reservation.endingDate ? moment(reservation.endingDate).format("DD/MM/YYYY") : "En cours"}</span></th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{reservation.garage_id?.place ?? "-"}</th>
                        <th scope="row" className={`px-6 py-4 font-medium whitespace-nowrap ${moment(reservation.endingDate).add(1, 'days').isBefore(moment(Date.now())) ? "line-through text-red-500" : null}`}>{reservation.access_code}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{reservation.sum}€</th>
                        <th scope="row" className="px-3 py-4 font-medium whitespace-nowrap"><a href={process.env.REACT_APP_API_URL + "/reservation/getpdf/" + reservation._id} target="_blank"><button className='p-3 bg-[#FCE415] rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer'><AiOutlineDownload size={20}/></button></a></th>
                        <th scope="row" className="px-3 py-4 font-medium whitespace-nowrap"><a href={process.env.REACT_APP_API_URL + "/reservation/getbail/?reservationId=" + reservation._id} target="_blank"><button className='p-3 bg-[#FCE415] rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer'><AiOutlineDownload size={20}/></button></a></th>

                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">
                          {reservation.subscription && !reservation.offer?.commitment ?
                          <a onClick={() => { 
                            setId(reservation._id)
                            setVisible(true) 
                            }} target="_blank"><button className='p-3 bg-[#fc1515] rounded-xl hover:bg-[#fc1515]/80 transition duration-300 font-semibold text-black cursor-pointer m-1'><AiOutlineClose size={20}/></button></a> : null}
                        </th>
                    </tr>
                ))}
                
            </tbody>
        </table>}
    </div>
  )
}