import axios from "axios";
import moment from "moment";

class reservationService {
    async getunavailabledates(garage_id: string) {
        let response = await fetch(process.env.REACT_APP_API_URL+"/reservation/getunavailableintervalsbygarageplace/"+ garage_id,{
            credentials: "include"
          });
        let data = response.json();
        return data;
    }

    async create(payload: any, offer_id: string, garage_id: string) {

        payload.starting_date = moment(payload.starting_date).format("MM-DD-YYYY");
        payload.ending_date = moment(payload.ending_date).format("MM-DD-YYYY");

        return axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + "/reservation/create",
            withCredentials:true,
            data: {...payload, offer_id: offer_id, garage_id: garage_id}
        })
    }

    async get() {

        let response = await fetch(process.env.REACT_APP_API_URL+"/reservation/get",{
            credentials: "include"
          });
        let data = response.json()
        return data;
    }

    async stopSubscription(payload: any) {
        return axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + "/reservation/stopsubscription",
            withCredentials:true,
            data: {
                reservation_id: payload
            }
        })
    }

    async preReservePlace(payload: any) {
        return axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + "/reservation/pre-reserve-place",
            withCredentials:false,
            data: payload
        })
    }
}

export default new reservationService()