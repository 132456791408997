import { Rings } from 'react-loading-icons';
import { useEffect, useState, useContext, useRef } from 'react';
import garageService from "../services/garageServices";
import MapCard from "../components/Home/mapCard";
import { appContext } from "../App";
import mapboxgl from 'mapbox-gl';


export const Garages = () => {
  const publicUrl = process.env.PUBLIC_URL;
  const mapMarkerImage = `${publicUrl}/assets/mapMarker.png`;
  const [garages, setGarages]: any = useState();
  const [mapPosition, setMapPositon]: any = useState([44.227638, 2.213749]);
  const userStatus = useContext(appContext);
  const [mapZoom, setMapZoom] = useState(4);

  const mapContainer = useRef<any>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [lng, setLng] = useState(mapPosition[1]);
  const [lat, setLat] = useState(mapPosition[0]);
  const [zoom, setZoom] = useState(mapZoom);


  useEffect(() => {

    if (map.current) return;
    garageService.get().then(async (res) => {
      setGarages(res.data)

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/navigation-night-v1',
        center: [lng, lat],
        zoom: zoom,
        maxZoom: res.data[0].adress.street ? 15 : 12
      });

      // Calculate bounds to include all markers
      const bounds = new mapboxgl.LngLatBounds();

      map.current?.on('load', async () => {
        const geojsonFeatures = [];
        map.current?.loadImage(mapMarkerImage, (error, image: any) => {
          map.current?.addImage('mappin', image);
        })

        for (const item of res.data) {
          // Include each marker's coordinates in the bounds
          bounds.extend([item.adress.long, item.adress.lat]);
          if (!item.adress.street) {
            const feature: any = {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [item.adress.long, item.adress.lat-0.001]
              },
            };
            geojsonFeatures.push(feature);
          }
        }

        map.current?.addSource('markers-source', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: geojsonFeatures
          }
        });

        map.current?.addLayer({
          id: 'markers-layer-circle',
          type: 'circle',
          source: 'markers-source',
          paint: {
            'circle-color': '#FCE415',
            'circle-radius': 45,
            'circle-stroke-width': 0.5,
            'circle-stroke-color': 'black',
            'circle-opacity': 0.5
          },
          minzoom: 11,
          maxzoom: 13
        });


        map.current?.addLayer({
          id: 'markers-layer',
          type: 'symbol',
          source: 'markers-source',
          layout: {
            'icon-image': 'mappin',
            'icon-size': 0.75,
            'icon-allow-overlap': true
          },
          minzoom: 0,
          maxzoom: 11
        });



        for (const item of res.data) {

          if (item.adress.street) {
            const el = document.createElement('div');
            el.className = 'marker';
            const marker = new mapboxgl.Marker().setLngLat([item.adress.long, item.adress.lat]).addTo(map.current!);
            const popupEl = document.createElement('div');
            popupEl.className = 'popup';
            popupEl.innerHTML =
              `Lieu : 
              <span className="text-green-500">${item.place}</span>
              </br>
              Emplacements disponibles : 
              <span className="text-green-500">${item.nbAvailablePlaces}</span>
              `;
            marker.setPopup(new mapboxgl.Popup().setDOMContent(popupEl));
          } else {
            // let polygonRequest: any = await geoServices.getPolygon(item.adress.city, item.adress.postal_code)
            // map.current?.addSource(item._id, {
            //   'type': 'geojson',
            //   'data': {
            //     type: "Feature",
            //     properties: {},
            //     geometry: {
            //       type: 'Polygon',
            //       coordinates: [
            //         polygonRequest[0].geometry.coordinates[0]
            //       ]
            //     },
            //   },
            // });
            map.current?.addSource(item._id, {
              type: 'geojson',
              data: {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [item.adress.long, item.adress.lat]
                },
                properties: {
                  radius: 10000
                }
              }
            });

            map.current?.addLayer({
              'id': item._id,
              'type': 'fill',
              'source': item._id, // reference the data source
              'layout': {},
              'paint': {
                'fill-color': '#FCE415', // blue color fill
                'fill-opacity': 0.5,
              },
              'minzoom': 9,
              'maxzoom': 16
            });

            map.current?.addLayer({
              'id': item._id,
              'type': 'line',
              'source': item._id,
              'layout': {},
              'paint': {
                'line-color': 'black',
                'line-width': 3
              },
              'minzoom': 9,
              'maxzoom': 14
            });

          }


        }

        // Set map to fit all markers
        map.current?.fitBounds(bounds, { padding: 50 }); // You can adjust padding as needed
      })


    })

    return () => {
      if (map.current) {
        map.current.remove();
      }
    };

  }, []);


  return (
    <div className="w-full h-auto flex flex-col justify-center items-center pt-[120px]">
      <h1 className='text-[18px] text-center'>Retrouvez ici tous nos garages disponibles en france.</h1>
      <div className="p-2 m-4 h-auto w-auto max-w-[90%] overflow-x-auto flex flex-row items-start styled-bar">
        {garages ? garages.map((garage: any, key: number) => (
          <MapCard garage={garage} key={key} verified={userStatus?.status == "verified"}
            handleNewView={(center: any) => {
              const bounds = new mapboxgl.LngLatBounds();
              bounds.extend(center);
              map.current?.fitBounds(bounds, { padding: 50 });
            }}
          />
        )) : <Rings />}
      </div>
      <div className="w-full md:w-1/2 text-center p-2" data-aos="fade-right">
        <div ref={mapContainer} style={{ height: "400px" }} />
        <h1 className='text-[12px] text-green-300 m-2'>Pour la sécurité de nos motards, l'adresse est {userStatus?.status == "verified" ? "confidentielle et ne doit être divulguée qu'à des utilisateurs vérifiés." : "visible qu'après validation du compte."}</h1>
      </div>
    </div>
  )
}
