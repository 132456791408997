import { appContext } from "../App";
import { useContext } from "react";
import { Navigate } from 'react-router-dom';


export const BlockNonAuth = ({children}: any) => {

  const userStatus = useContext(appContext);
  if(userStatus?.connected && !userStatus.admin) {
    return children;
  } else {  
      return <Navigate to="/" />
  }
  
}