import { useState, useContext, useRef, useEffect } from 'react';
import { appContext } from "../App";
import LogoWithText from '../components/logoWithText';

export const Franchise = () => {

  const publicUrl = process.env.PUBLIC_URL;
  const backgroundImage = `${publicUrl}/assets/images/franchise.png`;

  return (
    <div className="flex flex-col justify-center items-center">
      <section id="landing" className="w-full md:min-h-[100vh] min-h-[86vh] flex flex-col justify-center items-center">
        <div className="w-full h-full absolute brightness-[0.3]" style={{ zIndex: -1, backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }} />
        <LogoWithText/>
        <h2 className="text-[15px] max-w-[300px] text-center">Protégeons les motards avec impact, devenez franchisé</h2>
        <a href="mailto:contact@blackgarage.fr" className="mt-5 p-3 inline-block rounded-md bg-[#FCE415] text-[14px] text-black hover:bg-[#ccb910] duration-300">Contactez-nous</a>
      </section>
      
      <section data-aos="fade-up" className="w-[70%] text-center my-10">
        <h2 className="text-[30px] m-4 font-extrabold uppercase">Devenez un partenaire clé avec Black Garage : Optez pour la Franchise et Maximisez la Rentabilité de Vos Locaux</h2>
        <p>Vous avez des locaux inutilisés ou qui ne génèrent pas suffisamment de rentabilité ? Chez Black Garage, nous avons la solution idéale pour vous ! En rejoignant notre réseau de franchises, vous pouvez transformer ces espaces en une source de revenus stable et lucrative. Nous vous offrons un service de franchise tout inclus avec gestion des locations, vous permettant de profiter des avantages d'une franchise sans les tracas de la gestion quotidienne.</p>
        <p className="text-[18px] md:text-[22px] font-semibold m-5">Pourquoi nous rejoindre ?</p>
        <p>Nous répondons un a un besoin crucial. En rejoignant la franchise Black Garage, vous devenez un acteur clé dans la lutte contre le vol et le vandalisme croissants qui touchent de plus en plus les motards. Avec des chiffres alarmants montrant une augmentation constante des incidents, notre solution offre une réponse immédiate et efficace à ce problème pressant. Vous aiderez à sécuriser les motos de vos clients, leur offrant une tranquillité d'esprit tout en répondant à un besoin essentiel dans le marché.</p>
      </section>

      <section data-aos="fade-up" className="flex flex-col w-[70%] justify-center items-center md:text-left text-center gap-8 my-10">
        <h2 className="text-[30px] font-extrabold uppercase">Pourquoi Choisir la Franchise Black Garage ?</h2>
        <div className="w-full flex md:flex-row flex-col items-center md:justify-center justify-center h-auto">
          <h3 className="text-[30px] font-Neometric uppercase m-2 text-end">Modèle Éprouvé et Rentable</h3>
          <div className="md:h-[120px] h-[300px] w-[2px] bg-white m-2 hidden md:block"></div>
          <div className="md:h-[2px] h-[3px] w-full bg-white m-2 block md:hidden"></div>
          <p className="max-w-[500px] m-2 text-[12px]">Black Garage est une marque reconnue avec un modèle d'affaires éprouvé qui a démontré son efficacité. En optant pour notre franchise, vous bénéficiez de notre expérience et de notre savoir-faire pour maximiser la rentabilité de vos locaux.</p>
        </div>
        <div className="w-full flex md:flex-row flex-col items-center md:justify-center justify-center h-auto">
          <h3 className="text-[30px] font-Neometric uppercase m-2 text-end">Support et gestion complète</h3>
          <div className="md:h-[120px] h-[300px] w-[2px] bg-white m-2 hidden md:block"></div>
          <div className="md:h-[2px] h-[3px] w-full bg-white m-2 block md:hidden"></div>
          <p className="max-w-[500px] m-2 text-[12px]">Nous ne vous laissons pas seul dans cette aventure. Notre équipe vous accompagne à chaque étape : de l'aménagement initial de votre local par nos fournisseurs partenaire, à la gestion entière et quotidienne des locations. Nous fournissons également une formation continue pour vous assurer que vous et votre équipe restez à jour sur les meilleures pratiques du secteur.</p>
        </div>
        <div className="w-full flex md:flex-row flex-col items-center md:justify-center justify-center h-auto">
          <h3 className="text-[30px] font-Neometric uppercase m-2 text-end">Marketing et Visibilité</h3>
          <div className="md:h-[120px] h-[300px] w-[2px] bg-white m-2 hidden md:block"></div>
          <div className="md:h-[2px] h-[3px] w-full bg-white m-2 block md:hidden"></div>
          <p className="max-w-[500px] m-2 text-[12px]">En devenant franchisé Black Garage, vous profitez de notre stratégie marketing robuste qui inclut des campagnes en ligne et hors ligne. Nous nous occupons de tout pour attirer les clients vers votre local, vous assurant ainsi un flux constant de locataires potentiels.</p>
        </div>
        <div className="w-full flex md:flex-row flex-col items-center md:justify-center justify-center h-auto">
          <h3 className="text-[30px] font-Neometric uppercase m-2 text-end">Gestion Simplifiée des Locations</h3>
          <div className="md:h-[120px] h-[300px] w-[2px] bg-white m-2 hidden md:block"></div>
          <div className="md:h-[2px] h-[3px] w-full bg-white m-2 block md:hidden"></div>
          <p className="max-w-[500px] m-2 text-[12px]">La gestion des locations peut être complexe et chronophage. Chez Black Garage, nous prenons en charge cette tâche pour vous. Nous gérons les réservations, les paiements et les relations clients, vous permettant de vous concentrer sur d'autres aspects de votre activité ou de profiter simplement de votre temps libre.</p>
        </div>
      </section>

      <section data-aos="fade-up" className="flex flex-col w-[70%] justify-center items-center md:text-left text-center gap-8 my-10">
        <h2 className="text-[30px] font-extrabold uppercase">Comment fonctionne notre process ?</h2>
        <div className="w-full flex md:flex-row flex-col items-center md:justify-center justify-center h-auto">
          <h3 className="font-bold text-[8rem] mr-2">1</h3>
          <div className="md:h-[120px] h-[300px] w-[2px] bg-white m-2 hidden md:block"></div>
          <div className="md:h-[2px] h-[3px] w-full bg-white m-2 block md:hidden"></div>
          <p className="max-w-[500px] m-2 text-[12px]"><strong>Évaluation de Vos Locaux:</strong> Notre processus commence par une évaluation complète de vos locaux. Nous analysons leur emplacement, leur taille, leur état et leur potentiel de rentabilité pour déterminer la meilleure façon de les exploiter.</p>
        </div>
        <div className="w-full flex md:flex-row flex-col items-center md:justify-center justify-center h-auto">
          <h3 className="font-bold text-[8rem] mr-2">2</h3>
          <div className="md:h-[120px] h-[300px] w-[2px] bg-white m-2 hidden md:block"></div>
          <div className="md:h-[2px] h-[3px] w-full bg-white m-2 block md:hidden"></div>
          <p className="max-w-[500px] m-2 text-[12px]"><strong>Aménagement et Branding:</strong> Une fois l'évaluation terminée, nous vous aidons à aménager vos locaux selon les standards de Black Garage. Nous nous assurons que chaque espace reflète notre marque et offre une expérience optimale à vos futurs locataires.</p>
        </div>
        <div className="w-full flex md:flex-row flex-col items-center md:justify-center justify-center h-auto">
          <h3 className="font-bold text-[8rem] mr-2">3</h3>
          <div className="md:h-[120px] h-[300px] w-[2px] bg-white m-2 hidden md:block"></div>
          <div className="md:h-[2px] h-[3px] w-full bg-white m-2 block md:hidden"></div>
          <p className="max-w-[500px] m-2 text-[12px]"><strong>Formation et Support:</strong> Avant l'ouverture officielle de votre franchise, nous vous offrons une formation complète sur notre modèle d'affaires, nos procédures et nos outils de gestion. De plus, notre équipe est toujours disponible pour répondre à vos questions et vous fournir le support nécessaire.</p>
        </div>
        <div className="w-full flex md:flex-row flex-col items-center md:justify-center justify-center h-auto">
          <h3 className="font-bold text-[8rem] mr-2">4</h3>
          <div className="md:h-[120px] h-[300px] w-[2px] bg-white m-2 hidden md:block"></div>
          <div className="md:h-[2px] h-[3px] w-full bg-white m-2 block md:hidden"></div>
          <p className="max-w-[500px] m-2 text-[12px]"><strong>Lancement et Marketing:</strong> Votre local apparaîtra sur notre site web et les réservations seront directement géré par notre site web et notre équipe. Une fois que tout est en place, nous lançons des campagnes marketing ciblées pour promouvoir votre nouveau local. Nous utilisons divers canaux de communication, y compris les réseaux sociaux, les annonces en ligne et les partenariats locaux, pour attirer des locataires vers votre franchise.</p>
        </div>
        <div className="w-full flex md:flex-row flex-col items-center md:justify-center justify-center h-auto">
          <h3 className="font-bold text-[8rem] mr-2">5</h3>
          <div className="md:h-[120px] h-[300px] w-[2px] bg-white m-2 hidden md:block"></div>
          <div className="md:h-[2px] h-[3px] w-full bg-white m-2 block md:hidden"></div>
          <p className="max-w-[500px] m-2 text-[12px]"><strong>Gestion Opérationnelle:</strong> Après le lancement, nous nous occupons de la gestion quotidienne des locations. Nous nous assurons que chaque client reçoive le meilleur service possible, ce qui se traduit par des taux de satisfaction élevés et des locataires réguliers.</p>
        </div>
      </section>

      <section data-aos="fade-up" className="flex flex-col w-[70%] justify-center items-center text-center gap-8 my-10">
        <h2 className="text-[30px] font-extrabold uppercase">Les Avantages de Devenir Franchisé Black Garage</h2>
        <ul className="list-none">
          <li><strong>Rentabilité Accrue :</strong> En transformant vos locaux sous-utilisés en un espace de location Black Garage, vous maximisez leur potentiel de revenu.</li>
          <li className="mt-3"><strong>Revenu Passif :</strong> Grâce à notre gestion tout inclus, vous générez des revenus sans avoir à vous impliquer dans la gestion quotidienne.</li>
          <li className="mt-3"><strong>Réseau et Communauté :</strong> Vous rejoignez un réseau de franchisés Black Garage, bénéficiant d'un partage d'expérience et de conseils précieux.</li>
          <li className="mt-3"><strong>Innovation Continue :</strong> Nous nous engageons à innover constamment pour améliorer nos services et rester en tête du marché, vous assurant ainsi une offre toujours compétitive.</li>
        </ul>

        <h2 className="text-[20px] font-extrabold">Rejoignez-nous Dès Aujourd'hui !</h2>
        <p>Ne laissez plus vos locaux stagner et rejoignez le réseau de franchises Black Garage. Contactez-nous dès aujourd'hui sur contact@blackgarage.fr pour une consultation gratuite et découvrez comment nous pouvons transformer vos espaces en une source de revenus fiable et durable. Ensemble, faisons de vos locaux un succès avec Black Garage.</p>
      </section>
    </div>
  );
};
