import { Rings } from 'react-loading-icons';
import { Link } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import offerService from "../services/offerServices";
import garageServices from '../services/garageServices';
import { CardOffer } from '../components/Offers/cardOffer';
import { appContext } from "../App";
import { BlankCard } from '../components/Offers/blankCard';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "#1E2128"
  },
};

export const Offers = () => {

  const [offers, setOffers]: any = useState();
  const [garages, setGarages]: any = useState();
  const userStatus = useContext(appContext);
  const [selectedGarage, setSelectedGarage] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    garageServices.get().then((res) => {
      setSelectedGarage(res.data[0]._id)
      setGarages(res.data)
    });
  }, [])

  useEffect(() => {
    fetchOffers(selectedGarage);
  }, [selectedGarage])

  const fetchOffers = (selectedGarage: string) => {
    offerService.get(selectedGarage).then((res) => setOffers(res.data));
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="w-full sm:h-[100vh] flex flex-col justify-start items-center pt-[120px] text-center">
      <h1 className='text-[18px] text-green-300'>Veuillez choisir une ville.</h1>
      <select onChange={(e) => fetchOffers(e.target.value)} className='bg-[#ffffff]/5 p-3 rounded-md text-[18px] m-3 text-white'>
        {!garages ? null : garages.map((garage: any, key: number) => (
          <option className='bg-[#31313a] rounded-md text-[18px] text-white' key={key} value={garage._id}>{garage.place}</option>
        ))}
      </select>
      {userStatus?.status == "unverified" ?
        <>
          <h2 className='mt-4 m-2 text-[16px]'>Veuillez noter que vous devez avoir verifié votre compte avant de pouvoir souscrire à l'une des offres ci-dessous.</h2>
          <h3 className='mt-2 m-2 text-[14px]'>Pour cela veuillez vous rendre dans <Link to="/dashboard" className='underline hover:text-yellow-400 duration-200'>mon espace.</Link></h3>
        </> : null}
      <div className='flex flex-wrap justify-center items-center'>
        {offers && offers.length > 0 ? offers.map((offer: any, key: number) => (
          <CardOffer offer={offer} key={key} openModal={openModal} />
        )) : <BlankCard />}
      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <div className='w-full flex flex-col justify-center items-center'>
          <div className='p-4 text-center'>
            <h1 className='text-center text-[20px]'>Pour accéder aux détails des offres veuillez vous connecter.</h1>
            <p>Veuillez d'abord vous inscrire ou vous connecter afin d'accéder aux offres.</p>
          </div>
          <div>
            <Link to="/auth/login"><button className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer' onClick={closeModal}>Connexion</button></Link>
            <button className='p-3 bg-red-500 m-4 rounded-xl hover:bg-red-500/80 transition duration-300 font-semibold text-black cursor-pointer' onClick={closeModal}>Fermer</button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
