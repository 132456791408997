import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import userServices from '../../services/userServices';
import { Link, useParams } from 'react-router-dom';

export const ChangePassword = () => {

    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const { token } = useParams();


    const onSubmit = (e : any) => {
        e.preventDefault()
        if(password == repeatPassword) {
            if(password.length > 8) {
                if(token) {
                    userServices.changePasswordRequest(password, token).then((res : any) => {
                        if(res.data.error) {
                        toast.error(res.data.error)
                        } 
                
                        if(res.data.message) {
                        toast.success(res.data.message)
                        }
                    })
                }
            } else {
                toast.error("Mot de passe trop court. Minimum 8 caractères.")
            }
        } else {
            toast.error("Les mots de passe ne correspondent pas.")
        }
    }

  return (
    <div className='flex flex-col justify-center items-center h-[100vh]'>
    <form onSubmit={(e) => onSubmit(e)} className="text-white flex flex-col w-full sm:w-[30rem] p-2 justify-center items-center text-left">
        <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
        <label className="mb-1 text-gray-500 w-full" htmlFor="password">Nouveau mot de passe :</label>
        <input value={password} onChange={(e) => setPassword(e.target.value)} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="password"  placeholder="********" />
        <label className="mb-1 text-gray-500 w-full" htmlFor="password">Répéter le mot de passe:</label>
        <input  value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="password"  placeholder="********" />
        <Link className="hover:text-[#FCE415] duration-500" to="/auth/login">Retour à la connexion.</Link>
        <input type="submit" className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold w-[70%] text-black cursor-pointer' value="Connexion"/>
    </form>
</div>
  )
}