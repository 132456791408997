import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { BiAddToQueue } from "react-icons/bi";
import { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify'
import adminGarageServices from '../../../services/admin/adminGarageServices';

export const AdminGarages = () => {

  const [garages, setGarages] = useState([]);
  const [filteredGarages, setFilteredGarages] = useState([]);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);


  useEffect(() => {
    adminGarageServices.get().then((res) => {
      setGarages(res.data)
      setFilteredGarages(res.data)
    })
  }, [])

  useEffect(() => {
    if(!search) {
      setLimit(10)
    } else {
        setFilteredGarages(garages.filter((garage: any) => {
        return garage.place.toLowerCase().includes(search.toLowerCase()) 
        || garage.adress.street.toLowerCase().includes(search.toLowerCase()) 
        || garage.adress.city.toLowerCase().includes(search.toLowerCase()) 
      }));
    }
  }, [search])

  const onRemove = (id: string) => {
    adminGarageServices.delete(id)
    .then((res) => {
      setGarages(garages.filter((garage: any) => garage._id != id))
    })
    .catch((error) => toast.error(error.response.data.error))
  }

  return (
    <div className="w-full min-h-[100vh] flex flex-col justify-start items-center pt-[120px] text-center p-2">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <h1 className='text-[18px]'>Garages</h1>
      <input className="w-[60%] focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded p-2 m-6 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="johndoe@gmail.com" value={search} onChange={(e) => setSearch(e.target.value)}/>
      {/* <Link to="create"><button className='p-3 m-4 bg-[#FCE415] rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer flex'><BiAddToQueue className="mr-1" size={20}/> Ajouter</button></Link> */}
        {garages.length == 0 ? 
        <h3 className='mt-2 m-2 text-[14px]'>Aucun utilisateur pour le moment.</h3>
        :
        <table className="text-sm text-left md:min-w-[60%] overflow-x-auto shadow-xl self-start">
            <thead className="text-xs text-white/90 uppercase">
                <tr>
                    <th className="px-6 py-3">Id</th>
                    <th className="px-6 py-3">Emplacement</th>
                    <th className="px-6 py-3">Adresse</th>
                    <th className="px-6 py-3">Places maximales</th>
                    <th className="px-6 py-3">Action</th>
                </tr>
            </thead>
            <tbody className='[&>*:nth-child(odd)]:bg-[#23272F] [&>*:nth-child(even)]:bg-transparent'>
                {filteredGarages.map((garage: any, key: number) => (
                    <tr key={key} className="bg-white dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{garage._id}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{garage.place}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{garage.adress.street}, {garage.adress.postal_code} - {garage.adress.city}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-center">{garage.max_places}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">
                          <Link to={"edit/" + garage._id}><button className='m-2 p-3 bg-[#FCE415] rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer'><AiFillEdit size={20}/></button></Link>
                          <button onClick={() => onRemove(garage._id)} className='m-2 p-3 bg-[#fc1d15] rounded-xl hover:bg-[#fc1d15]/80 transition duration-300 font-semibold text-black cursor-pointer'><AiFillDelete size={20}/></button>
                        </th>
                    </tr>
                ))}
            </tbody>
        </table>}
    </div>
  )
}