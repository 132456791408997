
class garageService {
    async get() {
        let response = await fetch(process.env.REACT_APP_API_URL+"/garage/get",{
            credentials: "include"
        });
        let data = response.json()
        return data;
    }

    async getImages(garage: string) {
        let response = await fetch(process.env.REACT_APP_API_URL+ "/garage/getimages/" + garage + "/",{
            credentials: "include"
        });
        let data = response.json()
        return data;
    }
}

export default new garageService()