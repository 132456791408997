import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { appContext } from "../../../App";
import {AiFillEye } from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify'
import adminUserServices from "../../../services/admin/adminUserServices";
import { Dropdown } from 'primereact/dropdown';
import { ToggleButton } from 'primereact/togglebutton';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "#1E2128"
  },
};


export const AdminEditUser = () => {
    const userStatus = useContext(appContext);
    const navigate = useNavigate();
    const { id } = useParams();
    const [payload, setPayload]: any = useState({
        _id: id,
        name: "",
        last_name: "",
        email: "",
        status: "",
        balance: "",
        admin: false,
        bank_statement: "",
        documents: {},
    });
    
    const requiredFiles: any = {
      idCard: {
        name: "Carte d'identité recto/verso",
      },
      driverLicense: {
        name: "Permis de conduire",
      },
      vehicleRegistration: {
        name: "Carte grise de la moto",
      },
      vehicleInsurance: {
        name: "Assurance de la moto",
      },
      userInsurance: {
        name: "Assurance résponsabilité civile",
      },
      proofOfAddress: {
        name: "Justificatif de domicile",
      },
    };

    const status = [
        {name: 'verified', code: 'verified'},
        {name: 'unverified', code: 'unverified'},
        {name: 'pending', code: 'pending'}
    ];
    
    useEffect(() => {
        adminUserServices.getSingle(id).then(async(res) => {
            setPayload({...res});
        })
    }, [])

    const submitForm = (e: any) => {
      e.preventDefault();
      adminUserServices.updateAdmin({...payload, documents: {...payload.documents, "idCard":{"location":"https://minio-api.e-lyf.fr/black-garage-dev/64349ebd77dc36276b13767e/idCard.jpg","expirationDate":Date.now()}}})
      .then((res) => {
        toast.success(res.data.message)
      })
      .catch((error) => toast.error(error.response.data.error))
      
    }

  return (
    <div className="w-full min-h-[100vh] flex flex-col justify-start items-center pt-[120px] text-center p-2">
        <h1 className='text-[18px]'>Informations personnelles</h1>
        <button onClick={() => navigate(-1)} type="submit" className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold min-w-[150px] text-black cursor-pointer' value="Connexion">Retour</button>
        <form onSubmit={(e) => submitForm(e)} className="text-white flex flex-col w-auto max-w-[800px] p-2 justify-center items-center text-left">
          <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          />
          <div className='flex md:flex-row flex-col justify-center items-start'>
            <div className='m-4'>
            <label className="mb-1 text-gray-500 w-full" htmlFor="name">Nom :</label>
            <input value={payload.name} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="John" onChange={(e) => setPayload({...payload, name: e.target.value})}/>
            
            <label className="mb-1 text-gray-500 w-full" htmlFor="last_name">Nom de famille :</label>
            <input value={payload.last_name} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="Doe" onChange={(e) => setPayload({...payload, last_name: e.target.value})}/>
            
            <label className="mb-1 text-gray-500 w-full" htmlFor="password">Email :</label>
            <input value={payload.email} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="johndoe@gmail.com" onChange={(e) => setPayload({...payload, email: e.target.value})}/>
            
            <div className="flex flex-col">
                <label className="mb-1 text-gray-500 w-full" htmlFor="last_name">Solde :</label>
                <input value={payload.balance} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-[40%] p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="number"  placeholder="10" onChange={(e) => setPayload({...payload, balance: e.target.value})}/>
            </div>

            <div className="flex flex-col">
                <label className="mb-1 text-gray-500 w-full" htmlFor="last_name">RIB (IBAN) :</label>
                <input value={payload.bank_statement} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-[40%] p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="FR471756900..." onChange={(e) => setPayload({...payload, bank_statement: e.target.value})}/>
            </div>

            <div className="flex flex-col">
                <label className="mb-1 text-gray-500 w-full" htmlFor="last_name">Status :</label>
                <Dropdown className="mb-2 md:w-[40%]" optionLabel="name" value={{name: payload.status, code: payload.status}} options={status} onChange={(e) => setPayload({...payload, status: e.target.value.name})} placeholder="Select a City"/>
            </div>

            <div className="flex flex-col">
                <label className="mb-1 text-gray-500 w-full" htmlFor="last_name">Admin :</label>
                <ToggleButton onLabel="Oui" offLabel="Non" className="m-2 md:w-[40%]" onIcon="pi pi-check" offIcon="pi pi-times" checked={payload.admin} onChange={(e) => setPayload({...payload, admin : e.value})} />
            </div>
            
            <div className="flex flex-col">
              <label className="mt-3 mb-1 text-gray-500 w-full">Documents :</label>
              <div className='flex justify-center items-center'>
                {
                  Object.keys(payload.documents || {}).map(k => {
                    return <div onClick={() => window.open(payload?.documents[k]?.location, '_blank')?.focus()} className="bg-[#ffffff]/5 p-3 rounded-md hover:bg-[#ffffff]/10 duration-200 cursor-pointer m-2 flex justify-center items-center">
                      <AiFillEye size="15" className='m-2'/>{requiredFiles[k].name}
                    </div>
                  })
                }
              </div> 
            </div>
            </div>
          </div>
            <input type="submit" className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold w-[70%] text-black cursor-pointer' value="Sauvegarder"/>
        </form>
    </div>
  )
}