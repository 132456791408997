import { FaMapPin } from "react-icons/fa";
import { Link } from "react-router-dom";
import { appContext } from "../../App";
import { useContext, useState } from 'react';

export const CardOffer = ({ offer, openModal }: any) => {


  const userStatus = useContext(appContext);


  return (
    <Link onClick={() => openModal()} to={userStatus?.connected ? "checkout/" + offer._id.replace(/\s+/g, '').trim() : ""}>
      <div className="flex flex-col justify-center items-center text-center m-2 h-[270px] sm:w-[370px] w-[90vw] bg-[#ffffff]/5 shadow-md rounded-md hover:bg-[#ffffff]/10 duration-300 cursor-pointer">
        <h3 className="text-[18px] font-Neometric text-[#FCE415] m-2">{offer.name}</h3>
        <p className="m-2 text-[#ffffff]/50 text-[14px] underline">{offer.commitment ? `Avec engagement ${offer.duration} ${offer.subscribe ? "mois" : "jours"}` : "Sans engagement"}</p>
        <p className="m-2 text-[#ffffff]/50 text-[14px] underline">{offer.subscribe ? "Abonnement" : null}</p>
        <div className="w-[80%] h-[1px] bg-[#ffffff]/5 m-2"></div>
        <h2 className="text-[35px] font-Neometric">
          {offer.subscribe
            ? offer.monthly_cost
            : offer.daily_cost} €
          <span className="text-[20px]">
            {offer.subscribe
              ? " / mois"
              : " / jour"}
          </span>
        </h2>
      </div>
    </Link>
  )
}
