import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AiOutlineDownload, AiFillEye } from "react-icons/ai";
import { appContext } from "../../App";
import { useState, useEffect, useContext } from "react";
import moment from 'moment';
import userServices from '../../services/userServices';
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-modal';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "#1E2128"
  },
};

export const DashboardProfile = () => {

  const userStatus = useContext(appContext);
  const [user, setUser] = useState({
    subscription_status: ""
  });
  const [payload, setPayload] = useState({
    _id: "",
    email: "",
    password: "",
    name: "",
    last_name: "",
    bank_statement: ""
  })

  const [addressPayload, setAddressPayload]: any = useState({
    street_number: "",
    street: "",
    postal_code: ""
  })

  const [oldPassword, setOldPassword] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [doc, setDoc] = useState("");
  const [vehiculeRegistration, setVehiculeRegistration] = useState('');
  const [driverLicense, setDriverLicense] = useState('');
 
  useEffect(() => {
    userServices.getSingle()
    .then(async(res) => {
      setPayload({...res})
      setUser(res)
      setAddressPayload({
        street_number: res.adress ? res.adress.street.match(/(\d+)/)[0] : "",
        street: res.adress ? res.adress.street.match(/^(\d+)(.*)/)[2].trim() : "",
        postal_code: res.adress ? res.adress.postal_code : ""
      })
      if(res.documents.length > 0) {
        let vehicle = await fetch(process.env.REACT_APP_API_URL+ "/user/getdocuments/vehicleregistration/" + res._id,{
            credentials: "include"
        })
        let dataVehicle = await vehicle.json();
        setVehiculeRegistration(dataVehicle);
        let driver = await fetch(process.env.REACT_APP_API_URL+ "/user/getdocuments/driverlicense/" + res._id,{
            credentials: "include"
        })
        let dataDriver = await driver.json();
        setDriverLicense(dataDriver);
      }
    })
    .catch((error) => {
      if(error) {
        toast.error(error.response.data.error)
      }
      
    })
  }, [])

  const submitForm = (e: any) => {
    e.preventDefault()

    userServices.updateFullUser({...payload, oldPassword})
    .then(() => {
      userServices.updateAddress({...addressPayload})
      .then(() => {
        toast.success("Utilisateur mis a jour")
      })
      .catch((error: any) => {
        toast.error(error.response.data.error)
      })
    })
    .catch((error: any) => {
      toast.error(error.response.data.error)
    })


  }

  function openModal(param: string) {
    setDoc(param);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="w-full flex flex-col justify-start items-center pt-[120px] text-center p-2">
      <h1 className='text-[18px]'>Informations personnelles</h1>
      <form onSubmit={(e) => submitForm(e)} className="text-white flex flex-col w-auto max-w-[800px] p-2 justify-center items-center text-left">
        <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
            <div className='flex md:flex-row flex-col justify-center items-start'>
              <div className='m-4'>
                <label className="mb-1 text-gray-500 w-full" htmlFor="name">Prénom :</label>
                <input value={payload.name} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="john" onChange={(e) => setPayload({...payload, name: e.target.value})}/>
                
                <label className="mb-1 text-gray-500 w-full" htmlFor="last_name">Nom :</label>
                <input value={payload.last_name} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="doe" onChange={(e) => setPayload({...payload, last_name: e.target.value})}/>
                
                <label className="mb-1 text-gray-500 w-full" htmlFor="password">Email :</label>
                <input value={payload.email} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="johndoe@gmail.com" onChange={(e) => setPayload({...payload, email: e.target.value})}/>
                
                <label className="mb-1 text-gray-500 w-full" htmlFor="password">IBAN (RIB) :</label>
                <input value={payload.bank_statement} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="FR471756900..." onChange={(e) => setPayload({...payload, bank_statement: e.target.value})}/>
                
                <label className="mb-1 text-gray-500 w-full" htmlFor="password">Nouveau mot de passe :</label>
                <input value={payload.password} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="password" placeholder="*********" onChange={(e) => setPayload({...payload, password: e.target.value})}/>
                
                <div className="w-[90%] h-[2px] bg-[#ffffff]/5 m-4"></div>
                
                <label className="mb-1 text-gray-500 w-full" htmlFor="password">Mot de passe actuel<span className="text-red-400"> *obligatoire</span> :</label>
                <input value={oldPassword} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="password" placeholder="*********" onChange={(e) => setOldPassword(e.target.value)}/>
              </div>
              <div className='m-4'>
                <label className="mb-1 text-gray-500 w-full" htmlFor="name">Numero de la voie :</label>
                <input value={addressPayload.street_number} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="143" onChange={(e) => setAddressPayload({...addressPayload, street_number: e.target.value})}/>
                
                <label className="mb-1 text-gray-500 w-full" htmlFor="last_name">Nom de la rue / voie :</label>
                <input value={addressPayload.street} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="Rue de france" onChange={(e) => setAddressPayload({...addressPayload, street: e.target.value})}/>
                
                <label className="mb-1 text-gray-500 w-full" htmlFor="password">Code postal :</label>
                <input value={addressPayload.postal_code} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="75006" onChange={(e) => setAddressPayload({...addressPayload, postal_code: e.target.value})}/>
              </div>
            </div>
            <input type="submit" className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold w-[70%] text-black cursor-pointer' value="Sauvegarder"/>
        </form>
        {userStatus?.status == "pending" ? 
        <>
          <h3 className='mt-2 m-2 text-[14px]'>Notez que ces documents seront supprimés lorsque la verification aura été effectué.</h3>
          <div className='flex justify-center items-center'>
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
              <div className='w-full flex flex-col justify-center items-center'>
                {doc == "driverlicense" && driverLicense.toString().includes("pdf")|| doc == "vehicleregistration" && vehiculeRegistration.toString().includes("pdf")?
                <object data={doc == "driverlicense" ? driverLicense : vehiculeRegistration} className="w-[500px] h-[700px]" type="application/pdf"></object>
                : <img src={doc == "driverlicense" ? driverLicense : vehiculeRegistration} />}
                <button className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer'  onClick={closeModal}>Fermer</button>
              </div>
            </Modal>
            <div onClick={() => openModal("driverlicense")} className="bg-[#ffffff]/5 p-3 rounded-md hover:bg-[#ffffff]/10 duration-200 cursor-pointer m-2 flex justify-center items-center"><AiFillEye size="15" className='m-2'/>Mon permis de conduire</div>
            <div onClick={() => openModal("vehicleregistration")} className="bg-[#ffffff]/5 p-3 rounded-md hover:bg-[#ffffff]/10 duration-200 cursor-pointer m-2 flex justify-center items-center"><AiFillEye size="15" className='m-2'/>Ma carte grise</div>
          </div>
        </>: null}
    </div>
  )
}