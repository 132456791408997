import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { BiAddToQueue } from "react-icons/bi";
import { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'
import adminOfferServices from '../../../services/admin/adminOfferServices';

export const AdminOffers = () => {

  const [offers, setOffers] = useState([]);

  useEffect(() => {
    adminOfferServices.get().then((res) => {
      setOffers(res.data)
    })
  }, [])

  const onRemove = (id: string) => {
    adminOfferServices.delete(id)
    .then((res) => {
      setOffers(offers.filter((offer: any) => offer._id != id))
    })
    .catch((error) => toast.error(error.response.data.error))
  }

  return (
    <div className="w-full min-h-[100vh] flex flex-col justify-start items-center pt-[120px] text-center p-2">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <h1 className='text-[18px]'>Offres</h1>
      <Link to="create"><button className='p-3 m-4 bg-[#FCE415] rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer flex'><BiAddToQueue className="mr-1" size={20}/> Ajouter</button></Link>
        {offers.length == 0 ? 
        <h3 className='mt-2 m-2 text-[14px]'>Aucun utilisateur pour le moment.</h3>
        :
        <table className="text-sm text-left md:min-w-[60%] overflow-x-auto shadow-xl self-start">
            <thead className="text-xs text-white/90 uppercase">
                <tr>
                    <th className="px-6 py-3">Id</th>
                    <th className="px-6 py-3">Nom</th>
                    <th className="px-6 py-3">Cout journalier / mensuel</th>
                    <th className="px-6 py-3">Garage</th>
                    <th className="px-6 py-3">Durée (jours / mois)</th>
                    <th className="px-6 py-3">Engagement</th>
                    <th className="px-6 py-3">Abonnement</th>
                    <th className="px-6 py-3">Prix élec</th>
                    <th className="px-6 py-3">Action</th>
                </tr>
            </thead>
            <tbody className='[&>*:nth-child(odd)]:bg-[#23272F] [&>*:nth-child(even)]:bg-transparent'>
                {offers.map((offer: any, key: number) => (
                    <tr key={key} className="bg-white dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{offer._id}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{offer.name}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{offer.subscribe ? offer.monthly_cost : offer.daily_cost} €</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{offer.garage_id.place}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-center">{offer.duration ? offer.subscribe ? offer.duration + " mois" : offer.duration + " jours" : "-"} </th>
                        <th scope="row" className={`px-6 py-4 font-medium whitespace-nowrap ${offer.commitment ? "text-green-500" : null}`}>{offer.commitment ? "Avec engagement" : "Sans engagement"}</th>
                        <th scope="row" className={`px-6 py-4 font-medium whitespace-nowrap ${offer.subscribe ? "text-green-500" : null}`}>{offer.subscribe ? "Abonnement" : "-"}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-center">{offer.electricity_cost} €</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">
                          <Link to={"edit/" + offer._id}><button className='m-2 p-3 bg-[#FCE415] rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer'><AiFillEdit size={20}/></button></Link>
                          <button onClick={() => onRemove(offer._id)} className='m-2 p-3 bg-[#fc1d15] rounded-xl hover:bg-[#fc1d15]/80 transition duration-300 font-semibold text-black cursor-pointer'><AiFillDelete size={20}/></button>
                        </th>
                    </tr>
                ))}
            </tbody>
        </table>}
    </div>
  )
}