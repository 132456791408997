import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { appContext } from "../App";
import { AiFillCaretDown } from "react-icons/ai";

export const Services = () => {


  return (
    <div className="flex flex-col justify-center items-center">
      <section id="landing" className="w-full md:min-h-[100vh] min-h-[86vh] flex flex-col justify-center items-center">
        <div className="w-full h-full absolute brightness-[0.4]" style={{ zIndex: -1, backgroundImage: `url(${process.env.PUBLIC_URL+"/assets/images/services-background.png"})`, backgroundSize: "cover", backgroundPosition: "center"}}></div>
        <div className="flex flex-wrap justify-center items-center">
            <div className="flex flex-col justify-center items-center">
                <h1 className="uppercase text-[4em] font-Neometric">Nos services</h1>
                <AiFillCaretDown size={25}/>
            </div>
        </div>
      </section>
      <section id="avantages" className="w-[80%] text-center m-4">
        <h2 className="text-[30px] m-10 font-extrabold uppercase">Pourquoi nous faire confiance ?</h2>
        <div className="flex flex-col justify-center items-center md:text-left text-center gap-8">
          <div className="w-full flex md:flex-row flex-col items-center md:justify-center justify-center h-auto m-4">
            <h3 className="text-[30px] font-Neometric uppercase m-2">Service +</h3>
            <div className="md:h-[120px] h-[300px] w-[2px] bg-white m-2 hidden md:block"></div>
            <div className="md:h-[2px] h-[3px] w-full bg-white m-2 block md:hidden"></div>
            <p className="max-w-[500px] m-2 text-[12px]">
              Ici votre abonnement vous donne accès à de multiples services supplémentaires! Votre emplacement vous est livré avec un casier permettant d’y ranger et sécuriser jusqu’à 2 équipements moto. Pour l’identifier, votre casier est immatriculé du même numéro que votre emplacement. (Cadenas non fourni)

              Un compresseur est également à votre disposition afin de permettre directement sur place, la pressurisation des pneumatiques de votre moto avant de prendre la route.
              
              Et pour un supplément, les pannes de batteries ne seront plus qu’un lointain souvenir. Il vous sera possible d’effectuer le maintien en charge de la batterie de votre moto à l’aide de la prise mise à votre disposition sur votre emplacement ! (Consommation illimité)
            </p>
          </div>
          <div className="w-full flex md:flex-row flex-col items-center md:justify-center justify-center h-auto m-4">
            <h3 className="text-[30px] font-Neometric uppercase m-2">Sécurité accrue</h3>
            <div className="md:h-[120px] h-[300px] w-[2px] bg-white m-2 hidden md:block"></div>
            <div className="md:h-[2px] h-[3px] w-full bg-white m-2 block md:hidden"></div>
            <p className="max-w-[500px] m-2 text-[12px]">BLACK GARAGE a pour cœur de mettre la sécurité à son plus haut. C’est pourquoi notre équipe établie une vidéosurveillance 24H/24 et 7j/7 et vous alerte en cas d’anomalie. De plus chaque point BLACK GARAGE est muni d’alarme et d’un dispositif anti-intrusion.
            Chaque emplacement est doté de 3 points d’ancrages, permettant à votre convenance de sécuriser votre moto par un moyen de dispositif anti-vol type chaine / câble.
            </p>
          </div>
          <div className="w-full flex md:flex-row flex-col items-center md:justify-center justify-center h-auto m-4">
            <h3 className="text-[30px] font-Neometric uppercase m-2">Simplicité</h3>
            <div className="md:h-[120px] h-[300px] w-[2px] bg-white m-2 hidden md:block"></div>
            <div className="md:h-[2px] h-[3px] w-full bg-white m-2 block md:hidden"></div>
            <p className="max-w-[500px] m-2 text-[12px]">Sécuriser votre moto n’a jamais été aussi simple ! Il vous est à présent possible de souscrire à un abonnement au jour, au mois ou à l’année et sans engagement possible en quelques clics directement sur notre site web !
            Une fois votre abonnement obtenu, plus besoin de vous encombrer avec des tickets, des badges ou encore des clés… Passez à la nouvelle ère, un simple code d’accès unique pour chaque abonné vous est fourni !
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}
