import { AiFillEdit } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { useState, useEffect, useContext } from "react";
import { appContext } from "../../../App";
import { Link } from 'react-router-dom';
import { RadioButton } from 'primereact/radiobutton';
import adminUserServices from '../../../services/admin/adminUserServices';
import userServices from "../../../services/userServices";
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "#1E2128"
  },
};

export const AdminUsers = () => {

  const userStatus = useContext(appContext);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter]: any = useState("");
  const [userToDelete, setUserToDelete]: any = useState(null);

  useEffect(() => {
    adminUserServices.get().then((res) => {
      setUsers(res.data)
      setFilteredUsers(res.data)
    })
  }, [])

  useEffect(() => {
    if (filter != "" || filter != "none") {
      setFilteredUsers(filteredUsers.filter((user: any) => {
        return user.name.toLowerCase().includes(search.toLowerCase()) || user.last_name.toLowerCase().includes(search.toLowerCase()) || user.email.toLowerCase().includes(search.toLowerCase())
      }));
    } else {
      setFilteredUsers(users.filter((user: any) => {
        return user.name.toLowerCase().includes(search.toLowerCase()) || user.last_name.toLowerCase().includes(search.toLowerCase()) || user.email.toLowerCase().includes(search.toLowerCase())
      }));
    }
  }, [search])

  function openModal(userId: any) {
    setUserToDelete(userId);
  }

  function closeModal() {
    setUserToDelete(null);
  }

  useEffect(() => {
    switch (filter) {
      case (true):
        setFilteredUsers(users.filter((user: any) => {
          return user.admin == filter;
        }));
        break;
      case ("verified"):
        setFilteredUsers(users.filter((user: any) => {
          return user.status == filter;
        }));
        break;
      case ("pending"):
        setFilteredUsers(users.filter((user: any) => {
          return user.status == filter;
        }));
        break;
      default:
        setFilteredUsers(users);
        break;
    }
  }, [filter])


  const deleteUser = (id: string) => {
    userServices.deleteUser(id).then(() => {
      let copyUsers = users.filter((e: any) => e._id != id);
      setUsers(copyUsers);
      setFilter("none");
    })
    closeModal()
  }

  return (
    <div className="w-full min-h-[100vh] flex flex-col justify-start items-center pt-[120px] text-center p-2">
      <h1 className='text-[18px] mb-3'>Utilisateurs</h1>
      <input className="w-[60%] mb-0 focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded p-2 m-6 focus:outline-none focus:shadow-outline duration-100" type="text" placeholder="johndoe@gmail.com" value={search} onChange={(e) => {
        if (!e.target.value) {
          setFilteredUsers(users)
          setFilter("none")
        }
        setSearch(e.target.value)
      }} />
      <div className='flex justif-center items-center m-6'>
        <div className="m-2">
          <RadioButton inputId="none" name="radio-filter" value="none" onChange={(e) => setFilter(e.value)} checked={filter === 'none'} />
          <label htmlFor="none" className='m-2'>Aucun</label>
        </div>
        <div className="m-2">
          <RadioButton inputId="admin" name="radio-filter" value={true} onChange={(e) => setFilter(e.value)} checked={filter === true} />
          <label htmlFor="admin" className='m-2'>Admins</label>
        </div>
        <div className="m-2">
          <RadioButton inputId="verified" name="radio-filter" value="verified" onChange={(e) => setFilter(e.value)} checked={filter === 'verified'} />
          <label htmlFor="verified" className='m-2'>Utilisateurs verifiés</label>
        </div>
        <div className="m-2">
          <RadioButton inputId="pending" name="radio-filter" value="pending" onChange={(e) => setFilter(e.value)} checked={filter === 'pending'} />
          <label htmlFor="pending" className='m-2'>Utilisateurs en attente de verification</label>
        </div>
      </div>
      {users.length == 0 ?
        <h3 className='mt-2 m-2 text-[14px]'>Aucun utilisateur pour le moment.</h3>
        :
        <table className="text-sm text-left md:min-w-[60%] overflow-x-auto shadow-xl self-start">
          <thead className="text-xs text-white/90 uppercase">
            <tr>
              <th className="px-6 py-3">Id</th>
              <th className="px-6 py-3">Nom</th>
              <th className="px-6 py-3">Nom de famille</th>
              <th className="px-6 py-3">Email</th>
              <th className="px-6 py-3">Téléphone</th>
              <th className="px-6 py-3">Solde</th>
              <th className="px-6 py-3">Status</th>
              <th className="px-6 py-3">Admin</th>
              <th className="px-6 py-3">Action</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-[#23272F] [&>*:nth-child(even)]:bg-transparent'>
            {filteredUsers.map((user: any, key: number) => (
              <>
                <tr key={key} className="bg-white dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{user._id}</th>
                  <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{user.name}</th>
                  <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{user.last_name}</th>
                  <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{user.email}</th>
                  <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{user.phone_number}</th>
                  <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{user.balance}€</th>
                  <th scope="row" className={`px-6 py-4 font-medium whitespace-nowrap ${user.status == "verified" ? "text-green-500" : null}`}>{user.status}</th>
                  <th scope="row" className={`px-6 py-4 font-medium whitespace-nowrap ${user.admin ? "text-green-500" : null}`}>{user.admin ? "Admin" : "Utilisateur"}</th>
                  <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">
                    <Link to={"edit/" + user._id}><button className='p-3 m-2 bg-[#FCE415] rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer'><AiFillEdit size={20} /></button></Link>
                    {userStatus?.currentUser._id != user._id ? <button onClick={() => openModal(user._id)} className='p-3 m-2 bg-[#fc5615] rounded-xl hover:bg-[#fc5615]/50 transition duration-300 font-semibold text-black cursor-pointer'><RxCross1 size={20} /></button> : null}
                  </th>
                </tr>
              </>
            ))}
            <Modal isOpen={!!userToDelete} onRequestClose={closeModal} style={customStyles}>
              <div className='w-full flex flex-col justify-center items-center'>
                <div className='p-4 text-center'>
                  <h1 className='text-center text-[20px]'>Voulez vous vraiment supprimer cet utilisateur ?</h1>
                </div>
                <div>
                  <button onClick={() => deleteUser(userToDelete)} className='p-3 bg-red-500 m-4 rounded-xl hover:bg-red-500/80 transition duration-300 font-semibold text-black cursor-pointer'>Oui</button>
                  <button onClick={closeModal} className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer'>Non</button>
                </div>
              </div>
            </Modal>
          </tbody>
        </table>}
    </div>
  )
}