import { NavLink } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { appContext } from "../App";

export const DashboardNavbar = () => {

    const userStatus = useContext(appContext);

    const [nav, setNav] = useState(false);

    const toggleNav = () => {
        setNav(!nav)
    }

    const location = useLocation();
    return (    
        location.pathname.split("/")[1] != "dashboard" ? null : 
    
        <header className="w-full flex justify-center items-center text-white text-[14px] uppercase bg-transparent truncate absolute">
            <nav className="flex flex-col md:flex-row justify-center md:justify-around items-center xl:w-[80%] w-full">
                <div className="flex items-center justify-between w-full p-2" style={{backgroundColor: nav ? "#1e2128ad" : "transparent"}}>
                    <NavLink to="/"><img className="w-[180px]" src={process.env.PUBLIC_URL + "/assets/images/logo_white.png"} alt="logo_white"/></NavLink>
                    <div className="md:hidden mt-2 cursor-pointer" onClick={toggleNav}><FaBars size={25}/></div>
                </div>
                <ul className="md:flex p-2 flex-col md:flex-row justify-center items-center text-center md:space-x-10 md:w-auto w-full duration-300 hidden">
                    <NavLink className={({ isActive }) => isActive ? "m-2 md:m-0 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 md:m-0 hover:text-[#FCE415] duration-300"} to="/dashboard/">Tableau de bord</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "m-2 md:m-0 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 md:m-0 hover:text-[#FCE415] duration-300"} to="/dashboard/reservations">Reservations</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "m-2 md:m-0 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 md:m-0 hover:text-[#FCE415] duration-300"} to="/dashboard/profile">Profile</NavLink>
                    {!userStatus?.connected ?  <NavLink to="/" className="m-2 md:m-0 hover:text-[#FCE415] duration-300">Contact</NavLink> : 
                    <>
                    <NavLink className={({ isActive }) => isActive ? "m-2 md:m-0 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 md:m-0 hover:text-[#FCE415] duration-300"} to="/">Retour au site</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "m-2 md:m-0 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 md:m-0 hover:text-[#FCE415] duration-300"} to="/logout" ><BiLogOut size={30}/></NavLink>
                    </>}
                </ul>

                {nav ?
                <ul className="flex p-2 flex-col md:flex-row justify-center items-center text-center md:space-x-10 md:w-auto w-full duration-300 md:hidden bg-[#1e2128e8]">
                    <NavLink className={({ isActive }) => isActive ? "m-2 md:m-0 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 md:m-0 hover:text-[#FCE415] duration-300"} to="/dashboard/">Tableau de bord</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "m-2 md:m-0 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 md:m-0 hover:text-[#FCE415] duration-300"} to="/dashboard/reservations">Reservations</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "m-2 md:m-0 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 md:m-0 hover:text-[#FCE415] duration-300"} to="/dashboard/profile">Profile</NavLink>
                {!userStatus?.connected ?  <NavLink to="/" className="m-2 md:m-0 hover:text-[#FCE415] duration-300">Contact</NavLink> : 
                    <>
                    <NavLink className={({ isActive }) => isActive ? "m-2 md:m-0 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 md:m-0 hover:text-[#FCE415] duration-300"} to="/">Retour au site</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "m-2 md:m-0 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 md:m-0 hover:text-[#FCE415] duration-300"} to="/logout" ><BiLogOut size={30}/></NavLink>
                    </>}
            </ul>: null}
            </nav>
        </header>
        
        
    )
  }
  