import { appContext } from "../App";
import { useContext } from "react";
import { Navigate } from 'react-router-dom';


export const BlockRouteAuth = ({children}: any) => {
  const userStatus = useContext(appContext);
  if(userStatus?.connected) {
    return <Navigate to="/" />
  } else {  
      return children;
  }

}