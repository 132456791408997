import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { appContext } from "../../../App";
import { ToastContainer, toast } from 'react-toastify';
import garageServices from "../../../services/garageServices";
import adminOfferServices from "../../../services/admin/adminOfferServices";
import { ToggleButton } from 'primereact/togglebutton';


export const AdminEditOffer = () => {
  const userStatus = useContext(appContext);
  const [garages, setGarages]: any = useState();
  const navigate = useNavigate();
  const { id } = useParams();
  const [payload, setPayload] = useState({
    name: "",
    daily_cost: 0,
    monthly_cost: 0,
    electricity_cost: 0,
    duration: 0,
    subscribe: false,
    commitment: false,
    garage_id: "",
    confirmationMailText: "",
    confirmationMailPdf: "",
  });
  const [confirmationPdf, setConfirmationPdf]: any = useState(null);

  useEffect(() => {
    garageServices.get().then((res: any) => setGarages(res.data));
  }, [])

  useEffect(() => {
    adminOfferServices.getSingle(id).then((res) => {
      setPayload({ ...res });
    })

  }, [])

  const submitForm = (e: any) => {
    e.preventDefault();
    adminOfferServices.updateAdmin({...payload, confirmationMailPdf: confirmationPdf})
      .then((res) => {
        toast.success(res.data.message)
      })
      .catch((error) => toast.error(error.response.data.error))

  }

  return (
    <div className="w-full min-h-[100vh] flex flex-col justify-start items-center pt-[120px] text-center p-2">
      <button onClick={() => navigate(-1)} type="submit" className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold min-w-[150px] text-black cursor-pointer' value="Connexion">Retour</button>
      <h1 className='text-[18px]'>Détails de l'offre</h1>
      <form onSubmit={(e) => submitForm(e)} className="text-white flex flex-col w-auto max-w-[800px] p-2 justify-center items-center text-left">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className='flex md:flex-row flex-col justify-center items-start'>
          <div className='m-4'>
            <label className="mb-1 text-gray-500 w-full" htmlFor="name">Nom :</label>
            <input value={payload.name} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text" placeholder="Offre indeterminée" onChange={(e) => setPayload({ ...payload, name: e.target.value })} />

            <select onChange={(e) => setPayload({ ...payload, garage_id: e.target.value })} className='bg-[#ffffff]/5 p-3 rounded-md text-[14px] mt-3 text-white'>
              <option className='bg-[#31313a] rounded-md text-[14px] text-white' value={payload.garage_id}>Garder le même garage</option>
              {!garages ? null : garages.map((garage: any, key: number) => (
                <option className='bg-[#31313a] rounded-md text-[14px] text-white' key={key} value={garage._id}>{garage.place}</option>
              ))}
            </select>

            <div className="flex flex-col">
              <label className="mb-1 text-gray-500 w-full" htmlFor="last_name">Abonnement :</label>
              <ToggleButton onLabel="Oui" offLabel="Non" className="m-2 md:w-[40%]" onIcon="pi pi-check" offIcon="pi pi-times" checked={payload.subscribe} onChange={(e) => setPayload({ ...payload, subscribe: e.value })} />
            </div>

            {payload.subscribe ?
              <>
                <label className="mb-1 text-gray-500 w-full" htmlFor="last_name">Cout mensuel :</label>
                <input value={payload.monthly_cost} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text" placeholder="49.99" onChange={(e: any) => setPayload({ ...payload, daily_cost: 0, monthly_cost: e.target.value })} />
              </>
              :
              <>
                <label className="mb-1 text-gray-500 w-full" htmlFor="last_name">Cout journalier :</label>
                <input value={payload.daily_cost} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text" placeholder="4.25" onChange={(e: any) => setPayload({ ...payload, monthly_cost: 0, daily_cost: e.target.value })} />
              </>}

            <div className="flex flex-col mb-2 w-full">
              <label className="mb-1 text-gray-500 w-full" htmlFor="last_name">Prix de l'électricité :</label>
              <input value={payload.electricity_cost} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text" placeholder="0" onChange={(e: any) => setPayload({ ...payload, electricity_cost: e.target.value })} />
            </div>

            <div className="flex flex-col">
              <label className="mb-1 text-gray-500 w-full" htmlFor="last_name">Engagement :</label>
              <ToggleButton onLabel="Oui" offLabel="Non" className="m-2 md:w-[40%]" onIcon="pi pi-check" offIcon="pi pi-times" checked={payload.commitment} onChange={(e) => setPayload({ ...payload, commitment: e.value })} />
            </div>

            <div className="flex flex-col mt-2">
              {payload.commitment ?
                <>
                  <label className="mb-1 text-gray-500 w-full" htmlFor="password">Durée ({payload.subscribe ? "en mois" : "en jours"}):</label>
                  <input value={payload.duration} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text" placeholder="30" onChange={(e: any) => setPayload({ ...payload, duration: e.target.value })} />
                  {!payload.subscribe ? <h4 className="text-[15px]">cout total : {(payload.daily_cost * payload.duration)} €</h4> : null}
                </> : null}
            </div>

          </div>
        </div>
        <div className="w-full h-[1px] bg-gray-700"></div>
        <div className="w-full m-4">
          <div className="flex flex-col mb-2 w-full">
            <label className="mb-1 text-gray-500 w-full" htmlFor="last_name">Contenue de l'email de confirmation :</label>
            <textarea value={payload.confirmationMailText} onChange={(e) => setPayload({...payload, confirmationMailText: e.target.value})} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" name="email_content" id="emai_content" ></textarea>
          </div>
          <div className="m-2 flex flex-col">
            <label className="mb-1 text-gray-500 w-full">Pdf explication : {payload.confirmationMailPdf ? ("(" + payload.confirmationMailPdf.split("/").slice(-1)[0] + ")") : ""}</label>
            <label className="mb-1 rounded-xl text-gray-700 p-3 bg-[#FCE415] hover:bg-[#FCE415]/80 font-semibold w-[20%] text-center" htmlFor="pdf_file">{payload.confirmationMailPdf ? "Remplacer" : "Choisir"}</label>
            <input onChange={(e) => {if (e.target.files && e.target.files.length > 0) {console.log(e.target.files[0]); setConfirmationPdf(e.target.files[0]); setPayload({...payload, confirmationMailPdf: e.target.files[0].name})}}} className="hidden" type="file" name="pdf_file" id="pdf_file" />
          </div>
        </div>
        <input type="submit" className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold w-[70%] text-black cursor-pointer' value="Sauvegarder" />
      </form>
    </div>
  )
}