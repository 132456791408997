import { FaMapPin } from "react-icons/fa";
import { Link } from "react-router-dom";

export const BlankCard = ({offer}: any) => {

  return (
    <div className="flex flex-col justify-center items-center text-center m-2 h-[270px] sm:w-[370px] w-[90vw] bg-[#ffffff]/5 shadow-md rounded-md hover:bg-[#ffffff]/10 duration-300 cursor-pointer">
            <h3 className="text-[18px] font-Neometric text-[#FCE415] m-2">Bientot Disponible</h3>
            <div className="w-[80%] h-[1px] bg-[#ffffff]/5 m-2"></div>
            <h2 className="text-[35px] font-Neometric">

              <span className="text-[20px]">
              </span>
            </h2>
        </div>
  )
}
