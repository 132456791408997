import { useEffect, useState, useContext } from "react";
import moment from 'moment';
import { AiFillDelete } from "react-icons/ai";
import { appContext } from "../../App";
import { AdminHomeCards } from "../../components/adminDashboard/adminHomeCards";
import { TabView, TabPanel } from 'primereact/tabview';
import { Calendar } from 'primereact/calendar'; 
import userServices from "../../services/userServices";
import { ToastContainer, toast } from 'react-toastify';
import { BiSave } from 'react-icons/bi';
import voucherServices from "../../services/voucherServices";
import adminVoucherServices from "../../services/admin/adminVoucherServices";
import adminPromoServices from "../../services/admin/adminPromoServices";


export const AdminHome = () => {

  const userStatus = useContext(appContext);
  const [promos, setPromos] = useState([]);
  const [vouchers, setVouchers] = useState([]);

  const [payload, setPayload]: any = useState({
    value: 0,
    expire: ""
  });
  
  const [promoPayload, setPromoPayload]:any = useState({
    code: "",
    expire: "",
    discount: 0
  });
  const[reloader, setReloader]: any = useState(0);
  const[promoReloader, setPromoReloader]: any = useState(0);

  const [statToggler, setStatToggler] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);


  useEffect(() => {
    userServices.getstats().then((res) => {
      setCardData([
        {
          name: "Total chiffre d'affaire",
          amount: res.total_bill.length > 0 ? res.total_bill[0].total : 0,
          unit: "€"
        },
        {
          name: "Total utilisateurs",
          amount: res.users,
          unit: res.users > 1 ? "utilisateurs" : "utilisateur"
        },
        {
          name: "Total utilisateurs verifiés",
          amount: res.verified_users,
          unit: res.verified_users > 1 ? "utilisateurs" : "utilisateur"
        }

      ])
    })
  }, [statToggler])

  useEffect(() => {
    adminPromoServices.get().then((res) => {
        setPromos(res.data)
    })
  }, [promoReloader])

  useEffect(() => {
    adminVoucherServices.get().then((res) => {
      setVouchers(res.data)
    })
  }, [reloader])


  const [cardData, setCardData]: any = useState([
    {
      name: "Total chiffre d'affaire",
      amount: 0,
      unit: "€"
    },
    {
      name: "Total utilisateurs",
      amount: 0,
      unit: null
    },
    {
      name: "Total verifiés",
      amount: 0,
      unit: null
    }

  ])

  const postVoucher = (e: any) => {
    e.preventDefault();
    adminVoucherServices.create(payload)
    .then((res) => {
      setPayload({
        value: 0,
        expire: ""
      })
      setReloader(reloader + 1)
      toast.success(res.data.message)
    })
    .catch((error) => toast.error(error.response.data.error))
  }

  const postPromoCode = (e: any) => {
    e.preventDefault();
    adminPromoServices.create(promoPayload)
    .then((res) => {
      setPromoPayload({
        code: "",
        expire: "",
        discount: 0
      })
      setPromoReloader(promoReloader + 1)
      toast.success(res.data.message)
    })
    .catch((error) => toast.error(error.response.data.error))
  }

  const removeObject = (type: string, id: string) => {
    switch(type) {
      case "voucher": 
        adminVoucherServices.delete(id).then((res) =>  {
          setReloader(reloader + 1)
          toast.success(res.data.message)
        })
        .catch((error) => toast.error(error.response.data.error))
        return;
      case "promo": 
        adminPromoServices.delete(id).then((res) =>  {
          setPromoReloader(promoReloader + 1)
          toast.success(res.data.message)
        })
        .catch((error) => toast.error(error.response.data.error))
        return;
    }
  }
  

  return (
    <div className="w-full min-h-[100vh] h-auto p-4 flex flex-col justify-start items-center pt-[120px] text-center">
        <div className="w-full h-auto flex justify-center items-center flex-wrap">
          {cardData.map((data: any, key: number) => (
            <AdminHomeCards key={key} data={data}/>
          ))}
        </div>
        <div className="flex justify-start items-center p-4 h-[600px]">
          <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <TabPanel header="Bon d'achat">
                <form onSubmit={(e: any) => postVoucher(e)} className="m-4 text-white flex flex-col sm:w-[400px] w-[90%] p-2 justify-center items-center text-left">
                  <ToastContainer
                  position="top-center"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
                  />
                  <h3 className="text-[20px] mb-4">Créer un bon d'achat :</h3>
                  <label className="mb-1 text-gray-500 w-full">Valeur :</label>
                  <input className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded sm:w-full w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text" placeholder="25" value={payload.value} onChange={(e) => setPayload({...payload, value: e.target.value})}/>
                  <label className="mb-1 text-gray-500 w-full">Expire :</label>
                  <Calendar minDate={new Date()} dateFormat="dd-mm-yy" placeholder="DD-MM-AAAA" className="m-2 w-full" value={payload.expire} onChange={(e) => setPayload({...payload, expire: e.target.value})}></Calendar>
                  <input type="submit" className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold w-[50%] text-black cursor-pointer' value="Créer"/>
                </form>
            </TabPanel>
            <TabPanel header="Code promotionnels">
              <form onSubmit={(e: any) => postPromoCode(e)} className="m-4 text-white flex flex-col sm:w-[400px] w-[90%] p-2 justify-center items-center text-left">
                  <ToastContainer
                  position="top-center"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
                  />
                  <h3 className="text-[20px] mb-4">Créer un Code promotionnel :</h3>
                  <label className="mb-1 text-gray-500 w-full">Code :</label>
                  <input className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded sm:w-full w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text" placeholder="blackgarage15" value={promoPayload.code} onChange={(e) => setPromoPayload({...promoPayload, code: e.target.value})}/>
                  <label className="mb-1 text-gray-500 w-full">Promotion (reduction en %) :</label>
                  <input className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded sm:w-full w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text" placeholder="15" value={promoPayload.discount} onChange={(e) => setPromoPayload({...promoPayload, discount: e.target.value})}/>
                  <label className="mb-1 text-gray-500 w-full">Expire :</label>
                  <Calendar minDate={new Date()} dateFormat="dd-mm-yy" placeholder="DD-MM-AAAA" className="m-2 w-full" value={promoPayload.expire} onChange={(e) => setPromoPayload({...promoPayload, expire: e.target.value})}></Calendar>
                  <input type="submit" className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold w-[50%] text-black cursor-pointer' value="Créer"/>
              </form>
            </TabPanel>
          </TabView>
        </div>
        <div className="flex justify-start items-center">
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <TabPanel header="Bon d'achat">
            <table className="text-sm text-left md:min-w-[60%] overflow-x-auto self-start">
            <thead className="text-xs text-white/90 uppercase">
                <tr>
                    <th className="px-6 py-3">id</th>
                    <th className="px-6 py-3">Code</th>
                    <th className="px-6 py-3">Valeur</th>
                    <th className="px-6 py-3">Expiration</th>
                    <th className="px-6 py-3">Action</th>
                </tr>
            </thead>
            <tbody className='[&>*:nth-child(odd)]:bg-[#23272F] [&>*:nth-child(even)]:bg-transparent'>
                {vouchers.map((voucher: any, key: number) => (
                    <tr key={key} className="bg-white dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{voucher._id}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{voucher.code}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{voucher.value} €</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{moment(voucher.expire).format("DD/MM/YYYY")}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap"><button onClick={() => removeObject("voucher", voucher._id)} className='p-3 bg-[#FCE415] rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer'><AiFillDelete size={20}/></button></th>
                    </tr>
                ))}
                
            </tbody>
          </table>
            </TabPanel>
            <TabPanel header="Code promotionnels">
            <table className="text-sm text-left md:min-w-[60%] overflow-x-auto self-start">
            <thead className="text-xs text-white/90 uppercase">
                <tr>
                    <th className="px-6 py-3">Code</th>
                    <th className="px-6 py-3">Reduction (%)</th>
                    <th className="px-6 py-3">Expiration</th>
                    <th className="px-6 py-3">Action</th>
                </tr>
            </thead>
            <tbody className='[&>*:nth-child(odd)]:bg-[#23272F] [&>*:nth-child(even)]:bg-transparent'>
                {promos.map((promo: any, key: number) => (
                    <tr key={key} className="bg-white dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{promo.code}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{promo.discount}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{moment(promo.expire).format("DD/MM/YYYY")}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap"><button onClick={() => removeObject("promo", promo._id)} className='p-3 bg-[#FCE415] rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer'><AiFillDelete size={20}/></button></th>
                    </tr>
                ))}
                
            </tbody>
          </table>
            </TabPanel>
          </TabView>
        </div>
    </div>
  )
}
