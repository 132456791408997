import { AiOutlineDownload } from "react-icons/ai";
import { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from 'react-toastify'
import adminReservationsServices from '../../../services/admin/adminReservationsServices';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';

export const AdminReservations = () => {

  const [filteredReservations, setFilteredReservations] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [subscribe, setSubscribe] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    adminReservationsServices.get().then((res) => {
      setReservations(res)
      setSubscribe(res.filter((element: any) => element.subscribe ? element : null))
      const firstLimit = () => {
        return res.filter((reservation: any, key: number) => key < limit);
      }
      setFilteredReservations(firstLimit)
    })
  }, [])

  const changeReservation = () => {
    if(toggle) {
      setFilteredReservations(subscribe);
    } else {
      setFilteredReservations(reservations);
    }
  }

  useEffect(() => changeReservation, [toggle])

  useEffect(() => {
    if(!search) {
      setLimit(10)
    } else {
      setFilteredReservations(reservations.filter((reservation: any) => {
        return reservation.user.email.toLowerCase().includes(search.toLowerCase()) 
        || reservation.user.name.toLowerCase().includes(search.toLowerCase()) 
        || reservation.user.last_name.toLowerCase().includes(search.toLowerCase()) 
        || moment(reservation.createdAt).format('DD/MM/YYYY').includes(search.toString())
        || moment(reservation.startingDate).format('DD/MM/YYYY').includes(search.toString())
        || moment(reservation.endingDate).format('DD/MM/YYYY').includes(search.toString())
      }));
    }
  }, [search])

  const handleLoad = () => {
    let newArray = reservations.filter((reservation: any) => {
      return reservation.user.email.toLowerCase().includes(search.toLowerCase()) 
      || reservation.user.name.toLowerCase().includes(search.toLowerCase()) 
      || reservation.user.last_name.toLowerCase().includes(search.toLowerCase())
      || moment(reservation.createdAt).format('DD/MM/YYYY').includes(search.toString())
      || moment(reservation.startingDate).format('DD/MM/YYYY').includes(search.toString())
      || moment(reservation.endingDate).format('DD/MM/YYYY').includes(search.toString())
    });
    const firstLimit = () => {
      return newArray.filter((reservation: any, key: number) => key < limit + 10);
    }
    setLimit(limit + 10)
    setFilteredReservations(firstLimit)
  }

  return (
    <div className="w-full min-h-[100vh] flex flex-col justify-start items-center pt-[120px] text-center p-2">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <h1 className='text-[18px] mb-3'>Réservations</h1>
      <input className="w-[60%] focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded p-2 m-6 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="johndoe@gmail.com" value={search} onChange={(e) => setSearch(e.target.value)}/>
        <div className="flex align-items-center m-2" onClick={() => setToggle(!toggle)}>
          <Checkbox inputId="filter" name="filter" value="subscribe" checked={toggle} />
          <label htmlFor="filter" className="ml-2">Filtrer par abonnements</label>
        </div>
        {reservations.length == 0 ? 
        <h3 className='mt-2 m-2 text-[14px]'>Aucun utilisateur pour le moment.</h3>
        :
        <table className="text-sm text-left overflow-x-auto shadow-xl self-start">
            <thead className="text-xs text-white/90 uppercase">
                <tr>
                  <th className="px-6 py-3">N° de facture</th>
                  <th className="px-6 py-3">Date</th>
                  <th className="px-6 py-3">Debut - Fin</th>
                  <th className="px-6 py-3">Garage</th>
                  <th className="px-6 py-3">Utilisateur</th>
                  <th className="px-6 py-3">Code d'acces</th>
                  <th className="px-6 py-3">Total</th>
                  <th className="px-3 py-3">Reçu</th>
                  <th className="px-3 py-3">Bail</th>
                </tr>
            </thead>
            <tbody className='[&>*:nth-child(odd)]:bg-[#23272F] [&>*:nth-child(even)]:bg-transparent'>
              {filteredReservations.map((reservation: any, key: number) => (
                    <tr key={key} className="bg-white dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{reservation._id}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{moment(reservation.createdAt).format("DD/MM/YYYY")}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{moment(reservation.startingDate).format("DD/MM/YYYY")} - <span className={`${moment(reservation.endingDate).add(1, 'days').isBefore(moment(Date.now())) ? "line-through text-red-500" : "text-green-500"}`}>{reservation.endingDate ? moment(reservation.endingDate).format("DD/MM/YYYY") : "En cours"}</span></th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{reservation.garage_id ? reservation.garage_id.place : '-'}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{reservation.user.email}</th>
                        <th scope="row" className={`px-6 py-4 font-medium whitespace-nowrap ${moment(reservation.endingDate).add(1, 'days').isBefore(moment(Date.now())) ? "line-through text-red-500" : null}`}>{reservation.access_code}</th>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{reservation.sum}€</th>
                        <th scope="row" className="px-3 py-4 font-medium whitespace-nowrap"><a href={process.env.REACT_APP_API_URL + "/reservation/getpdf/" + reservation._id} target="_blank"><button className='p-3 bg-[#FCE415] rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer'><AiOutlineDownload size={20}/></button></a></th>
                        <th scope="row" className="px-3 py-4 font-medium whitespace-nowrap"><a href={process.env.REACT_APP_API_URL + "/reservation/getbail/?reservationId=" + reservation._id} target="_blank"><button className='p-3 bg-[#FCE415] rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer'><AiOutlineDownload size={20}/></button></a></th>
                    </tr>
                ))}
            </tbody>
        </table>}
        <button onClick={() => handleLoad()} className="w-[60%] focus:ring-1 focus:ring-yellow-400 bg-yellow-600 shadow border focus:border-yellow/50 border-transparent rounded p-2 m-6 focus:outline-none focus:shadow-outline duration-100">Charger plus</button>
    </div>
  )
}