import { Carousel } from 'primereact/carousel';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect, useState } from 'react';
import { FaMapPin } from "react-icons/fa";
import { appContext } from "../../App";
import garageService from "../../services/garageServices";
import reservationServices from '../../services/reservationServices';
import { Rings } from 'react-loading-icons';

const ReservationPopup = ({ garageId }: any) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ successfullySent, setSuccessfullySent ] = useState(false);
  const [ form, setForm ] = useState({
    garageId,
    date: '',
    name: '',
    email: '',
    phone: '',
    option: ''
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    reservationServices.preReservePlace(form).finally(() => {
      setIsLoading(false);
      setSuccessfullySent(true);
    })
  };

  return <>
    {isLoading
      ? <div className='flex flex-col justify-center items-center'>
        <Rings />
      </div>
      : (successfullySent
        ? <p className="text-[1rem] text-center">Merci pour votre demande, nous vous recontacterons dès disponibilité.</p>
        : <>
          <h2 className="text-lg font-bold mb-4">Demande de réservation gratuite et sans engagement</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block mb-1">Souhaitez vous un emplacement sécurisé pour :</label>
              <div className="space-y-2">
                <div>
                  <input
                    type="radio"
                    id="option1"
                    name="option"
                    value="1 jour ou plusieurs jours"
                    checked={form.option === '1 jour ou plusieurs jours'}
                    onChange={handleChange}
                    className="mr-2"
                    required
                  />
                  <label htmlFor="option1">1 jour ou plusieurs jours</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="option2"
                    name="option"
                    value="Un abonnement mensuel"
                    checked={form.option === 'Un abonnement mensuel'}
                    onChange={handleChange}
                    className="mr-2"
                    required
                  />
                  <label htmlFor="option2">Un abonnement mensuel</label>
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="date" className="block mb-1">Date envisagé :</label>
              <input
                type="text"
                id="date"
                name="date"
                value={form.date}
                onChange={handleChange}
                className="w-full p-2 rounded-md bg-gray-800 text-white"
                required
              />
            </div>
            <div>
              <label htmlFor="name" className="block mb-1">Nom/prénom:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={form.name}
                onChange={handleChange}
                className="w-full p-2 rounded-md bg-gray-800 text-white"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block mb-1">Mail:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                className="w-full p-2 rounded-md bg-gray-800 text-white"
                required
              />
            </div>
            <div>
              <label htmlFor="phone" className="block mb-1">Tel:</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={form.phone}
                onChange={handleChange}
                className="w-full p-2 rounded-md bg-gray-800 text-white"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full p-2 rounded-md bg-yellow-500 text-black font-bold"
            >
              Envoyer ma demande
            </button>
          </form>
        </>
      )
    }
  </>;
};

const MapCard = ({garage, handleNewView, verified}: any) => {

  const userStatus = useContext(appContext);
  const [visible, setVisible] = useState(false);
  const [images, setImages] = useState([]);
  const [popupPreOrder, setPopupPreOrder] = useState(false);

  const responsiveOptions = [
    {
      breakpoint: '4000px',
      numVisible: 1,
      numScroll: 1
    },
    {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '991px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1
    }
  ];

  useEffect(() => {
    garageService.getImages(garage._id).then((res) => {
      setImages(res)
    });
  }, [])

  const imageTemplate = (image: any) => {
    return (
      garage.images.length > 0 && (userStatus?.status == "verified" || userStatus?.admin) ?
      <img src={image} alt="" /> : null
      
    );
  };

  return (
    <>
      <div onClick={() => handleNewView([garage.adress.long, garage.adress.lat])} className="flex flex-col justify-center items-center m-2 cursor-pointer hover:bg-[#272b35] duration-300 p-2 rounded-sm sm:text-center text-left">
          <FaMapPin size={20} className="m-2"/>
          <div className="flex flex-col text-center">
              <p className='text-[15px] underline'>{garage.place}</p>
              <p>Emplacements disponibles : <span className={garage.nbAvailablePlaces > 0 ? `text-green-300` : `text-red-500`}>{garage.nbAvailablePlaces}</span></p>
              {verified ? <p>{garage.adress.street} , {garage.adress.postal_code}</p> : null}
          </div>
          {parseInt(garage.nbAvailablePlaces) === 0
            ? <button onClick={(e: any) => {
                e.stopPropagation();
                setPopupPreOrder(true);
                }} 
                className='p-1 m-1 bg-[#FCE415] rounded-md hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer'
              >Pré-réserver</button>
            : null
          }
          {garage.images.length > 0 && (userStatus?.status == "verified" || userStatus?.admin) ?
          
          <button onClick={(e: any) => {
            e.stopPropagation()
            setVisible(true)
            }} className='p-1 m-1 bg-[#FCE415] rounded-md hover:bg-[#FCE415]/80 transition duration-300 font-semibold text-black cursor-pointer'>Photos</button> : null}
      </div>
      <Dialog header="Pré-réservation" visible={popupPreOrder} onHide={() => setPopupPreOrder(false)}>
        <div className="card">
          <ReservationPopup garageId={garage._id} />
        </div>
      </Dialog>
      <Dialog header="Images" visible={visible} onHide={() => setVisible(false)}
          style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
         <div className="card">
            <Carousel value={images} circular={true} page={1} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions} itemTemplate={(image) => imageTemplate(image)} />
        </div>
      </Dialog>
    </>
  )
}

export default MapCard