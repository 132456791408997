import axios from "axios";

class adminOfferServices {

    async get() {
        let response = await fetch(process.env.REACT_APP_API_URL+"/offer/get",{
            credentials: "include"
          });
        let data = response.json()
        return data;
    }

    async getSingle(id: any) {
        let response = await fetch(process.env.REACT_APP_API_URL+"/offer/get/"+ id,{
            credentials: "include"
          });
        let data = response.json()
        return data;
    }
    
    async updateAdmin(payload: any) {
      return axios({
        method: 'patch',
        url: process.env.REACT_APP_API_URL + "/offer/update/" + payload._id,
        withCredentials:true,
        headers: {
          "Content-Type": "multipart/form-data",
          'Access-Control-Allow-Origin': '*',
        },
        data: {...payload}
      })
    }

    async create(payload: any) {
      return axios({
        method: 'post',
        url: process.env.REACT_APP_API_URL + "/offer/create/",
        withCredentials:true,
        data: {...payload}
      })
    }
    
    async delete(id: string) {
      return axios({
        method: 'delete',
        url: process.env.REACT_APP_API_URL + "/offer/" + id,
        withCredentials:true
      })
    }

}

export default new adminOfferServices()