import axios from "axios";

class userService {
    async getstats() {
        let response = await fetch(process.env.REACT_APP_API_URL+"/user/getstats",{
            credentials: "include"
          });
        let data = response.json()
        return data;
    }

    async changeEmail(payload: any) {
        return axios({
            method: 'patch',
            url: process.env.REACT_APP_API_URL + "/user/mailupdate",
            withCredentials:true,
            data: {...payload}
        })
    }
    
    async requestForVerification(payload: Object) {
        return axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + "/user/verificationrequest",
            withCredentials:true,
            headers: {
                "Content-Type": "multipart/form-data",
                'Access-Control-Allow-Origin': '*',
            },
            data: {...payload}
        })
    }

    async getSingle() {
        let response = await fetch(process.env.REACT_APP_API_URL+"/user/getsingle",{
            credentials: "include"
          });
        let data = response.json()
        return data;
    }

    async updateFullUser(payload: Object) {
        return axios({
            method: 'put',
            url: process.env.REACT_APP_API_URL + "/user/updatefull",
            withCredentials:true,
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            data: {...payload}
        })
    }

    async updateAddress(payload: Object) {
        return axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + "/user/editadress",
            withCredentials:true,
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            data: {...payload}
        })
    }

    async resetPasswordRequest(email: string) {
        return axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + "/user/resetpassword",
            withCredentials:true,
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            data: {email}
        })
    }
    
    async changePasswordRequest(password: string, token : string) {
        return axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + "/user/resetpassword/"+ token,
            withCredentials:true,
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            data: {password}
        })
    }

    async deleteUser(id: string) {
        return axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + "/user/admindeleteuser",
            withCredentials:true,
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                _id: id
            }
        });
    }
}

export default new userService()