import { useState, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { appContext } from "../../../App";
import { ToastContainer, toast } from 'react-toastify'
import adminGarageServices from "../../../services/admin/adminGarageServices";
import axios from "axios";


export const AdminEditGarages = () => {
    const userStatus = useContext(appContext);
    const navigate = useNavigate();
    const { id } = useParams();
    const [filesNumber, setFilesNumber] = useState<number | string>('');
    let inputRef = useRef<any | null>(null);
    const [payload, setPayload]: any = useState({
        garage_id: id,
        place: "",
        max_places: 0
    });
    
    const handleClick = () => {
      inputRef.current.click()
    }


    useEffect(() => {
        adminGarageServices.getSingle(id).then((res) => {
            setPayload({
              ...payload,
                place: res.place,
                max_places: res.max_places
            });
        })
        
    }, [])

    const submitForm = (e: any) => {
      e.preventDefault();
      adminGarageServices.update({...payload, images: inputRef.current.files})
      .then((res) => {
        toast.success(res.data.message)
      })
      .catch((error) => toast.error(error.response.data.error))
    }

    const handleFileChange = (event: any) => {
      setFilesNumber(event.target.files.length)
      const fileObj = event.target.files && event.target.files[0];
      if (!fileObj) {
          return;
      }
    }

    const removeImages = (id : any) => {
      adminGarageServices.removeImages({garage: id})
      .then((res) => {
        toast.success(res.data.message)
      })
    }

  return (
    <div className="w-full min-h-[100vh] flex flex-col justify-start items-center pt-[120px] text-center p-2">
        <button onClick={() => navigate(-1)} type="submit" className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold min-w-[150px] text-black cursor-pointer' value="Connexion">Retour</button>
        <h1 className='text-[18px]'>Détails de l'offre</h1>
        <form onSubmit={(e) => submitForm(e)} className="text-white flex flex-col w-auto max-w-[800px] p-2 justify-center items-center text-left">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <div className='flex md:flex-row flex-col justify-center items-start'>
            <div className='m-4'>
              <label className="mb-1 text-gray-500 w-full" htmlFor="name">Emplacement :</label>
              <input value={payload.place} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="Saint-Etienne" onChange={(e) => setPayload({...payload, place: e.target.value})}/>
              
              <label className="mb-1 text-gray-500 w-full" htmlFor="last_name">Places maximales:</label>
              <input value={payload.max_places} className="focus:ring-1 focus:ring-yellow-400 bg-[#23272F] shadow border focus:border-yellow/50 border-transparent rounded w-full p-2 mb-4 focus:outline-none focus:shadow-outline duration-100" type="text"  placeholder="4.25" onChange={(e: any) => setPayload({...payload, max_places: e.target.value})}/>
              <div>
                <div className="m-2 flex flex-col">
                  <label className="mb-1 text-gray-500 w-full" htmlFor="last_name">Photos : </label>
                  <input type="file" name="images" id="images" ref={inputRef} onChange={handleFileChange} multiple/>
                </div>
              </div>
            </div>
          </div>
            <input type="submit" className='p-3 bg-[#FCE415] m-4 rounded-xl hover:bg-[#FCE415]/80 transition duration-300 font-semibold w-[70%] text-black cursor-pointer' value="Sauvegarder"/>
        </form>
        <div className="m-2 flex flex-col">
                  <button onClick={() => removeImages(id)} className='p-3 bg-[#fc2415] m-2 rounded-xl hover:bg-[#fc2415]/80 transition duration-300 font-semibold text-white cursor-pointer max-w-[250px]'> Supprimer toutes les images</button>
                </div>
    </div>
  )
}