import { NavLink } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { appContext } from "../App";

export const AdminNavbar = () => {

    const userStatus = useContext(appContext);

    const [nav, setNav] = useState(false);

    const toggleNav = () => {
        setNav(!nav)
    }

    const location = useLocation();
    return (    
        location.pathname.split("/")[1] != "admin" ? null : 
    
        <header className="w-full flex justify-center items-center text-white text-[14px] uppercase bg-transparent truncate absolute">
            <nav className="flex flex-col xl:flex-row justify-center xl:justify-around items-center xl:w-[80%] w-full">
                <div className="flex items-center justify-between w-full p-2" style={{backgroundColor: nav ? "#1e2128ad" : "transparent"}}>
                    <NavLink to="/"><img className="min-w-[180px] max-w-[180px]" src={process.env.PUBLIC_URL + "/assets/images/logo_white.png"} alt="logo_white"/></NavLink>
                    <div className="xl:hidden mt-2 cursor-pointer" onClick={toggleNav}><FaBars size={25}/></div>
                </div>
                <ul className="xl:flex p-2 flex-col xl:flex-row justify-center items-center text-center xl:space-x-10 xl:w-auto w-full duration-300 hidden">
                    <NavLink className={({ isActive }) => isActive ? "m-2 xl:m-0 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 md:m-0 hover:text-[#FCE415] duration-300"} to="/admin/">Tableau de bord</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "m-2 xl:m-0 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 md:m-0 hover:text-[#FCE415] duration-300"} to="/admin/users">Utilisateurs</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "m-2 xl:m-0 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 md:m-0 hover:text-[#FCE415] duration-300"} to="/admin/offers">Offres</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "m-2 xl:m-0 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 md:m-0 hover:text-[#FCE415] duration-300"} to="/admin/reservations">Reservations</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "m-2 xl:m-0 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 md:m-0 hover:text-[#FCE415] duration-300"} to="/admin/garages">Garages</NavLink>
                    {!userStatus?.connected ?  <NavLink to="/" className="m-2 xl:m-0 hover:text-[#FCE415] duration-300">Contact</NavLink> : 
                    <>
                    <NavLink className={({ isActive }) => isActive ? "m-2 xl:m-0 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 md:m-0 hover:text-[#FCE415] duration-300"} to="/">Retour au site</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "m-2 xl:m-0 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 md:m-0 hover:text-[#FCE415] duration-300"} to="/logout" ><BiLogOut size={30}/></NavLink>
                    </>}
                </ul>

                {nav ?
                <ul className="flex p-2 flex-col xl:flex-row justify-center items-center text-center xl:space-x-10 xl:w-auto w-full duration-300 xl:hidden bg-[#1e2128e8]">
                    <NavLink className={({ isActive }) => isActive ? "m-2 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 hover:text-[#FCE415] duration-300"} to="/admin/">Tableau de bord</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "m-2 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 hover:text-[#FCE415] duration-300"} to="/admin/users">Utilisateurs</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "m-2 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 hover:text-[#FCE415] duration-300"} to="/admin/offers">Offres</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "m-2 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 hover:text-[#FCE415] duration-300"} to="/admin/reservations">Reservations</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "m-2 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 hover:text-[#FCE415] duration-300"} to="/admin/garages">Garages</NavLink>
                {!userStatus?.connected ?  <NavLink to="/" className="m-2 xl:m-0 hover:text-[#FCE415] duration-300">Contact</NavLink> : 
                    <>
                    <NavLink className={({ isActive }) => isActive ? "m-2 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 hover:text-[#FCE415] duration-300"} to="/">Retour au site</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "m-2 hover:text-[#ffffff] text-[#FCE415] duration-300" : "m-2 hover:text-[#FCE415] duration-300"} to="/logout" ><BiLogOut size={30}/></NavLink>
                    </>}
            </ul>: null}
            </nav>
        </header>
        
        
    )
  }
  