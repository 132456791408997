import axios from "axios";

class adminReservationsServices {
    async get() {
        let response = await fetch(process.env.REACT_APP_API_URL+"/reservation/get",{
            credentials: "include"
          });
        let data = response.json()
        return data;
    }
}

export default new adminReservationsServices()