import axios from "axios";
import moment from "moment";

class paymentService {
    async createCheckoutSession(offerId?: string, startingDate?: any, endingDate?: any, useElec?: Boolean, useBalance?: any, coupon?: any,
                                customAmountCoupon?: any) {

        const payload = {
            offerId,
            startingDate: startingDate ? moment(startingDate).format("MM-DD-YYYY") : null,
            endingDate: endingDate ? moment(endingDate).format("MM-DD-YYYY") : null,
            useElec,
            useBalance,
            coupon,
            customAmountCoupon,
        }

        return axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + "/payment/create-checkout-session",
            withCredentials:true,
            data: payload
        })
    }

    // async get() {

    //     let response = await fetch(process.env.REACT_APP_API_URL+"/reservation/get",{
    //         credentials: "include"
    //       });
    //     let data = response.json()
    //     return data;
    // }

    // async stopSubscription(payload: any) {
    //     return axios({
    //         method: 'post',
    //         url: process.env.REACT_APP_API_URL + "/reservation/stopsubscription",
    //         withCredentials:true,
    //         data: {
    //             reservation_id: payload
    //         }
    //     })
    // }
}

export default new paymentService()