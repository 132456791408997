import { Link } from "react-router-dom";
import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import MapCard from "../components/Home/mapCard";
import { appContext } from "../App";
import garageService from "../services/garageServices";
import geoServices from "../services/geoServices";
import Carousel from 'react-spring-3d-carousel';
import PhoneCarousel from "../components/Home/phoneCarousel";
import mapboxgl from 'mapbox-gl';
import LogoWithText from "../components/logoWithText";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN ? process.env.REACT_APP_MAPBOX_TOKEN : ""

const newYears = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0).getTime();
const timeConnected = new Date().getTime();

export const Home = () => {
  const publicUrl = process.env.PUBLIC_URL;
  const mapMarkerImage = `${publicUrl}/assets/mapMarker.png`;
  const [mapPosition, setMapPositon]: any = useState([46.232138, 2.203749]);
  const [mapZoom, setMapZoom] = useState(5);
  const [garages, setGarages]: any = useState();
  const userStatus = useContext(appContext);
  const [goToSlide, setGoToSlide] = useState(0);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const mapContainer = useRef<any>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [lng, setLng] = useState(mapPosition[1]);
  const [lat, setLat] = useState(mapPosition[0]);
  const [zoom, setZoom] = useState(mapZoom);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {

    if (map.current) return;
    garageService.get().then(async (res) => {
      setGarages(res.data)

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/navigation-night-v1',
        center: [lng, lat],
        zoom: zoom,
        maxZoom: res.data[0].adress.street ? 15 : 12
      });

      // Calculate bounds to include all markers
      const bounds = new mapboxgl.LngLatBounds();

      map.current?.on('load', async () => {
        const geojsonFeatures = [];
        map.current?.loadImage(mapMarkerImage, (error, image: any) => {
          map.current?.addImage('mappin', image);
        })

        for (const item of res.data) {
          // Include each marker's coordinates in the bounds
          bounds.extend([item.adress.long, item.adress.lat]);

          if (!item.adress.street) {
            const feature: any = {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [item.adress.long, item.adress.lat]
              },
            };
            geojsonFeatures.push(feature);
          }
        }

        map.current?.addSource('markers-source', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: geojsonFeatures
          }
        });

        map.current?.addLayer({
          id: 'markers-layer-circle',
          type: 'circle',
          source: 'markers-source',
          paint: {
            'circle-color': '#FCE415',
            'circle-radius': 45,
            'circle-stroke-width': 0.5,
            'circle-stroke-color': 'black',
            'circle-opacity': 0.5
          },
          minzoom: 11,
          maxzoom: 13
        });


        map.current?.addLayer({
          id: 'markers-layer',
          type: 'symbol',
          source: 'markers-source',
          layout: {
            'icon-image': 'mappin',
            'icon-size': 0.75,
            'icon-allow-overlap': true
          },
          minzoom: 0,
          maxzoom: 11
        });

        for (const item of res.data) {

          if (item.adress.street) {
            const el = document.createElement('div');
            el.className = 'marker';
            const marker = new mapboxgl.Marker().setLngLat([item.adress.long, item.adress.lat]).addTo(map.current!);
            const popupEl = document.createElement('div');
            popupEl.className = 'popup';
            popupEl.innerHTML =
              `Lieu : 
            <span className="text-green-500">${item.place}</span>
            </br>
            Emplacements disponibles : 
            <span className="text-green-500">${item.nbAvailablePlaces}</span>
            `;
            marker.setPopup(new mapboxgl.Popup().setDOMContent(popupEl));
          } else {
            // let polygonRequest: any = await geoServices.getPolygon(item.adress.city, item.adress.postal_code)
            // map.current?.addSource(item._id, {
            //   'type': 'geojson',
            //   'data': {
            //     type: "Feature",
            //     properties: {},
            //     geometry: {
            //       type: 'Polygon',
            //       coordinates: [
            //         polygonRequest[0].geometry.coordinates[0]
            //       ]
            //     },
            //   },
            // });
            console.log(item.adress.long, item.adress.lat)
            console.log(item._id)
            map.current?.addSource(item._id, {
              type: 'geojson',
              data: {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [item.adress.long, item.adress.lat]
                },
                properties: {
                  radius: 10000
                }
              }
            });

            map.current?.addLayer({
              'id': item._id,
              'type': 'fill',
              'source': item._id, // reference the data source
              'layout': {},
              'paint': {
                'fill-color': '#FCE415', // blue color fill
                'fill-opacity': 0.5,
              },
              'minzoom': 9,
              'maxzoom': 16
            });

            map.current?.addLayer({
              'id': item._id,
              'type': 'line',
              'source': item._id,
              'layout': {},
              'paint': {
                'line-color': 'black',
                'line-width': 3
              },
              'minzoom': 9,
              'maxzoom': 14
            });

          }


        }

        // Set map to fit all markers
        map.current?.fitBounds(bounds, { padding: 50 }); // You can adjust padding as needed
      })
    })

    return () => {
      if (map.current) {
        map.current.remove();
      }
    };

  }, [lat, lng, mapMarkerImage, zoom]);


  function string(number: number) {
    var tempnum; tempnum = Math.round(number) + "";
    if (tempnum.length > 3) {
      tempnum = tempnum.substring(0, tempnum.length - 3) + " " + tempnum.substring(tempnum.length - 3, 99);
    }
    if (tempnum.length > 7) {
      tempnum = tempnum.substring(0, tempnum.length - 7) + " " + tempnum.substring(tempnum.length - 7, 99);
    }
    if (tempnum.length > 11) {
      tempnum = tempnum.substring(0, tempnum.length - 11) + " " + tempnum.substring(tempnum.length - 11, 99);
    }
    switch (tempnum.length) {
      case 11: tempnum = " " + tempnum;
        break;
      case 10: tempnum = " " + tempnum;
        break;
      case 9: tempnum = " " + tempnum;
        break;
      case 8: tempnum = " " + tempnum;
        break;
      case 6: tempnum = " " + tempnum;
        break;
      case 5: tempnum = " " + tempnum;
        break;
      case 3: tempnum = " " + tempnum;
        break;
      case 2: tempnum = " " + tempnum;
        break;
      case 1: tempnum = " " + tempnum;
        break;
    }
    return tempnum;
  }

  const [statSinceJanuary, setStatSinceJanuary] = useState("");
  const [statSinceConnected, setStatSinceConnected] = useState("");

  const timeRemaining = useCallback(() => {
    const tps = 0.001756;
    let secondsConnected = new Date().getTime() - timeConnected;
    let secondsSinceNewYear = (new Date().getTime() - newYears) + secondsConnected;
    setStatSinceJanuary((secondsSinceNewYear * tps / 1000).toString());
    setStatSinceConnected((secondsConnected * tps / 1000).toString());
    setTimeout(timeRemaining, 1000);
  }, []);

  useEffect(() => {
    timeRemaining();
  }, [timeRemaining]);

  const slides = [
    {
      key: 1,
      content: <div className={"w-[75vw] md:w-[50vw] h-[35rem] md:h-[25rem] flex flex-row items-center justify-center overflow-hidden rounded-lg" + (goToSlide === 0 ? '' : ' blur-[0.2rem]')}>
        <div className={"duration-300 hover:scale-110 brightness-[0.6] blur-[0.1rem] h-full w-full"} style={{ objectFit: "cover", backgroundImage: 'url(/assets/images/carousel/' + (windowSize[0] >= 768 ? "" : "m") + '1.jpg)', backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "bottom" }}></div>
        <div className="p-5 absolute text-[16px]">
          <h2 className="text-[20px] mb-5 font-extrabold uppercase">LE SAVIEZ VOUS ?</h2>
          <p>93% des motos volées seraient directement
            hissées et embarquées dans un véhicule par les
            voleurs.</p>
          <p className="mt-5">81% des motos et scooters volés étaient munis d’un
            dispositif antivol électronique et ou mécanique</p>
          <p className="mt-5">Seul <span className="font-extrabold">BLACK GARAGE</span> apporte une réelle sécurité.</p>
          <p className="mt-5 text-[10px] text-right">Source : FFMC / Rapports de la police et de la gendarmerie nationale</p>
        </div>
      </div>
    },
    {
      key: 2,
      content: <div className={"w-[75vw] md:w-[50vw] h-[25rem] flex flex-row items-center justify-center overflow-hidden rounded-lg"}>
        <img className="duration-300 hover:scale-110 brightness-[0.25] blur-[0.1rem] h-full w-full" style={{ objectFit: "cover" }} src={'/assets/images/carousel/' + (windowSize[0] >= 768 ? "" : "m") + '2.jpg'} alt="" />
        <div className="p-5 absolute text-[16px]">
          <h2 className="text-[30px] mb-5 font-extrabold uppercase">LE SAVIEZ VOUS ?</h2>
          <p>En France, une moto ou un scooter est volé toutes les 10 minutes.</p>
          <p className="mt-5">86 % de ces vols se déroulent en milieu urbain.</p>
          <p className="mt-5">Seul <span className="font-extrabold">BLACK GARAGE</span> apporte une réel sécurité.</p>
          <p className="mt-5 text-[10px] text-right">Source : FFMC / Rapports de la police et de la gendarmerie nationale</p>
        </div>
      </div>
    },
    {
      key: 3,
      content: <div className={"w-[75vw] md:w-[50vw] h-[25rem] flex flex-row items-center justify-center overflow-hidden rounded-lg"}>
        <img className="duration-300 hover:scale-110 brightness-[0.25] blur-[0.1rem] h-full w-full" style={{ objectFit: "cover" }} src={'/assets/images/carousel/' + (windowSize[0] >= 768 ? "" : "m") + '3.jpg'} alt="" />
        <div className="p-8 absolute text-[16px]">
          <p> Afin que BLACK GARAGE soit présent dans tous
            vos déplacements, faites nous part des lieux
            dans lequel vous souhaitez que votre moto soit
            réellement sécurisée.</p>
          <p className="mt-5">Une ville ou un quartier en particulier ?</p>
          <p>Faites nous en part ici !</p>
          <a href="mailto:contact@blackgarage.fr" className="mt-5 p-3 flex items-center justify-center rounded-md bg-[#FCE415] text-[14px] text-black hover:bg-[#ccb910] duration-300">Envoyer un message.</a>
        </div>
      </div>
    },
    {
      key: 4,
      content: <div className={"w-[75vw] md:w-[50vw] h-[25rem] flex flex-row items-center justify-center overflow-hidden rounded-lg"}>
        <div className="duration-300 hover:scale-110 brightness-[0.25] blur-[0.1rem] h-full w-full" style={{ objectFit: "cover", backgroundImage: 'url(/assets/images/carousel/' + (windowSize[0] >= 768 ? "" : "m") + '4.jpg)', backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
        <div className="p-5 absolute text-[16px]">
          <h2 className="text-[20px] mb-5 font-extrabold uppercase">Une question ?</h2>
          <p>Faites nous en part ici !</p>
          <p>Nous répondrons volontiers à vos requêtes.</p>
          <a href="mailto:contact@blackgarage.fr" className="mt-5 p-3 flex items-center justify-center rounded-md bg-[#FCE415] text-[14px] text-black hover:bg-[#ccb910] duration-300">Envoyer un message.</a>
        </div>
      </div>
    },
    {
      key: 5,
      content: <div className={"w-[75vw] md:w-[50vw] h-[25rem] flex flex-row items-center justify-center overflow-hidden rounded-lg"}>
        <img className="duration-300 hover:scale-110 brightness-[0.25] blur-[0.1rem] h-full w-full" style={{ objectFit: "cover" }} src={'/assets/images/carousel/' + (windowSize[0] >= 768 ? "" : "m") + '5.jpg'} alt="" />
        <div className="p-5 absolute text-[16px]">
          <h2 className="text-[20px] mb-5 font-extrabold uppercase">Statistiques : </h2>
          <p>Les vols de motos et de scooters en France ;</p>
          <p>depuis le 1er janvier : <span className="font-extrabold">{parseInt(statSinceJanuary)}</span></p>
          <p>depuis que vous êtes connecté : <span className="font-extrabold">{parseInt(statSinceConnected) + 1}</span></p>
        </div>
      </div>
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => setGoToSlide(index) };
  });

  return (
    <div className="flex flex-col justify-center items-center">
      <section id="landing" className="w-full md:min-h-[100vh] min-h-[86vh] flex flex-col justify-center items-center">
        <div className="w-full h-full absolute brightness-[0.3]" style={{ zIndex: -1, backgroundImage: window.innerWidth > 768 ? `url(${process.env.PUBLIC_URL + "/assets/images/background.jpg"})` : `url(${process.env.PUBLIC_URL + "/assets/images/background-phone.jpg"})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }} />
        <LogoWithText/>
        <h2 className="text-[15px] max-w-[300px] text-center">Garez votre moto en toute sécurité dans l'un de nos garages en france.</h2>
        <div className="flex flex-wrap justify-center items-center">
          {userStatus?.connected ? null :
            <>
              <Link to="/auth/login" className="m-2 p-3 rounded-md bg-[#FCE415] text-[14px] text-black font-semibold hover:bg-[#ccb910] duration-300">Connexion</Link>
              <Link to="/auth/register" className="m-2 p-3 rounded-md bg-[#FCE415] text-[14px] text-black font-semibold hover:bg-[#ccb910] duration-300">Inscription</Link>
            </>}
        </div>
      </section>
      <section className="w-[100%] sm:px-[15%] h-[40rem] pb-[10rem] overflow-hidden" style={{ transform: 'translate3d(0%, 0, 0)' }}>
        {windowSize[0] >= 768 ?
          <Carousel
            slides={slides}
            goToSlide={goToSlide}
            // offsetRadius={this.state.offsetRadius}
            showNavigation={false}
          />

          : <div className="flex justify-center items-center mt-[80px]">
            <PhoneCarousel products={slides} statSinceJanuary={parseInt(statSinceJanuary)} statSinceConnected={parseInt(statSinceConnected) + 1} />
          </div>}

        {windowSize[0] >= 768 ??
          <div className="flex w-full items-center justify-around">
            <button onClick={() => setGoToSlide((6 + goToSlide - 1) % 6)} type="button" className="p-carousel-prev p-link" aria-label="Prev"><span className="p-carousel-prev-icon pi pi-chevron-left"></span></button>
            <button onClick={() => setGoToSlide((goToSlide + 1) % 6)} type="button" className="p-carousel-next p-link" aria-label="Next"><span className="p-carousel-next-icon pi pi-chevron-right"></span></button>
          </div>}


      </section>
      <section data-aos="fade-up" id="find-us" className={`w-[70%] h-auto text-center m-4`}>
        <h2 className="text-[20px] mb-5 font-extrabold uppercase">qui sommes-nous ?</h2>
        <p className="mt-4 text-xl">Black Garage, la référence en France du gardiennage pour moto</p>
        <p className="mt-2 text-justify">Nous sommes fiers de vous présenter notre entreprise fondée avec un objectif clair : résoudre le problème des vols de moto, tout en offrant un service de première classe.</p>
        <p className="mt-2 text-justify">Durant de longues
          années, nous avons travaillé dur pour développer une solution innovante dans l’objectif
          de répondre aux attentes des motards. Nous voulions vous offrir la possibilité
          de voyager sans vous soucier de la sécurité de votre moto et sans vous
          encombrer de votre tenue de protection. C'est ainsi que Black Garage est né.</p>
        <p className="mt-2 mb-10 text-justify">Chez Black Garage, nous sommes là pour vous offrir la tranquillité d'esprit. Découvrez un nouveau standard de sécurité et de confort.</p>
      </section>
      <section data-aos="fade-up" id="find-us" className="w-[70%] h-auto text-center m-4">
        <h2 className="text-[20px] p-4 mt-10 font-extrabold uppercase">où nous trouver ?</h2>
        <p className="m-2 text-[15px]">Retrouvez ici tout nos garages disponibles en france.</p>
        <div className="flex justify-center md:items-start items-center flex-col">
          <div className="p-2 m-4 h-auto w-auto max-w-[90%] overflow-x-auto flex flex-row items-start styled-bar">
            {garages ? garages.map((garage: any, key: number) => (
              <MapCard garage={garage} key={key} verified={userStatus?.status == "verified"} 
                handleNewView={(center: any) => {
                  const bounds = new mapboxgl.LngLatBounds();
                  bounds.extend(center);
                  map.current?.fitBounds(bounds, { padding: 50 });
                }}
              />
            )) : null}
          </div>
          <div className="w-full" data-aos="fade-right">
            <div ref={mapContainer} style={{ height: "400px" }} />
            <h1 className='text-[12px] text-green-300 m-2'>Pour la sécurité de nos motards, l'adresse est {userStatus?.status == "verified" ? "confidentielle et ne doit être divulguée qu'à des utilisateurs vérifiés." : "visible qu'après validation du compte."}</h1>
          </div>
        </div>
      </section>
    </div>
  )
}
