import axios from "axios";

class adminPromoServices {
    async create(payload: object) {
        return axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + "/promo/create",
            withCredentials:true,
            data: {...payload}
        })
    }

    async delete(id: string) {
        return axios({
            method: 'delete',
            url: process.env.REACT_APP_API_URL + "/promo/" + id,
            withCredentials:true
        })
    }

    async get() {
        let response = await fetch(process.env.REACT_APP_API_URL+"/promo/get",{
            credentials: "include"
          });
        let data = response.json()
        return data;
    }
}

export default new adminPromoServices()