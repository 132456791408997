import axios from "axios";

class voucherService {
    async creditBalance(voucher: string) {
        return axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + "/voucher/creditbalance",
            withCredentials:true,
            data: {code: voucher}
        })
    }

    async get() {
        let response = await fetch(process.env.REACT_APP_API_URL+"/voucher/get",{
            credentials: "include"
          });
        let data = response.json()
        return data;
    }
}

export default new voucherService()