
class authService {
    async check() {
        let response = await fetch(process.env.REACT_APP_API_URL+"/auth/checkconnected",{
            credentials: "include"
          });
        let data = response.json()
        return data;
    }

    async logout() {
        let response = await fetch(process.env.REACT_APP_API_URL+"/auth/logout", {
            credentials: "include"
        });
        let data = response.json()
        return data;
    }
}

export default new authService()