import { Link, useLocation } from "react-router-dom";


const Footer = () => {

  const location = useLocation();

  return (
    location.pathname == "/auth/login" 
    || location.pathname == "/auth/register" ? null :
    <footer className="w-full h-auto bg-[#23272F] flex flex-col justify-center items-center">
        <img className="w-[200px]" src={process.env.PUBLIC_URL + "/assets/images/logo_white.png"} alt="logo_white"/>
        <div className="flex items-center">
            <Link to="/assets/documents/CGV black garage.pdf" target="_blank" rel="noopener noreferrer" className="m-2 hover:text-[#FCE415] duration-500">Conditions générales de vente</Link>
            <Link to="/assets/documents/Black Garage - Mentions Légales.pdf" target="_blank" rel="noopener noreferrer" className="m-2 hover:text-[#FCE415] duration-500">Mentions légales</Link>
            <a href="mailto:contact@blackgarage.fr" className="m-2 hover:text-[#FCE415] duration-500">Contactez-nous</a>
        </div>
        <div className="flex items-center text-center">
          
          <div className="m-2 flex flex-col justify-center items-center">
            <a rel="license"  href="http://creativecommons.org/licenses/by-nc-nd/4.0/">
              <img alt="Licence Creative Commons" style={{borderWidth: 0, margin: '10px'}} src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png" />
            </a>
            <p className="text-[8px]">Ce(tte) œuvre est mise à disposition selon les termes de la</p>
            <a rel="license" className="text-[8px]"  href="http://creativecommons.org/licenses/by-nc-nd/4.0/">Licence Creative Commons Attribution - Pas d&#39;Utilisation Commerciale - Pas de Modification 4.0 International.</a>
          </div>
        </div>
    </footer>
  )
}

export default Footer